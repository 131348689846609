import { CreateOrUpdateConditionRequestBodyMetricPeriodEnum } from "@models/api";
import { EntitlementValueType, PriceBehaviourType } from "@models/entitlement";
import { Feature, FeatureType } from "@models/feature";
import { Plan } from "@models/plan";
import * as Yup from "yup";

export const MAX_NUMBER_OF_CREATE_ENTITLEMENT = 10;

export interface EntitlementValidationContext {
  plan?: Plan;
}

export const editEntitlementValidationSchema = (
  context: EntitlementValidationContext,
) => {
  return Yup.object().shape({
    _context: Yup.object().default(context).notRequired(),
    featureId: Yup.string().required("Required"),
    metricPeriod: Yup.string().when(["feature", "valueType"], {
      is: (feature: Feature, valueType: EntitlementValueType) =>
        feature?.featureType == FeatureType.Event &&
        valueType !== EntitlementValueType.Unlimited,
      then: () => Yup.string().required("Required"),
    }),
    metricPeriodMonthReset: Yup.string().when("metricPeriod", {
      is: (metricPeriod: CreateOrUpdateConditionRequestBodyMetricPeriodEnum) =>
        metricPeriod ===
        CreateOrUpdateConditionRequestBodyMetricPeriodEnum.CurrentMonth,
      then: () => Yup.string().required("Required"),
    }),
    planId: Yup.string().required("Required"),
    valueNumeric: Yup.string().when("valueType", {
      is: (valueType: EntitlementValueType) =>
        valueType === EntitlementValueType.Numeric,
      then: () => Yup.string().required("Required"),
    }),
    trait: Yup.string().when(["valueType", "priceBehavior"], {
      is: (valueType: EntitlementValueType, pb: string) =>
        valueType === EntitlementValueType.Trait && !pb,
      then: () => Yup.mixed().required("Required"),
      otherwise: () => Yup.mixed(),
    }),
    valueTraitId: Yup.string().when(["valueType", "priceBehavior"], {
      is: (valueType: EntitlementValueType, pb: string) =>
        valueType === EntitlementValueType.Trait && !pb,
      then: () => Yup.string().required("Required"),
    }),
    valueType: Yup.string().required("Required"),
    priceBehavior: Yup.string().nullable(),
    monthlyMeteredPrice: Yup.lazy(() => {
      let pb: boolean = false;
      return Yup.object()
        .nullable()
        .when(["priceBehavior", "yearlyMeteredPrice"], {
          is: function (priceBehavior: string, yearlyMeteredPrice: object) {
            if (
              priceBehavior === PriceBehaviourType.PayInAdvance ||
              priceBehavior === PriceBehaviourType.PayAsYouGo
            ) {
              pb = true;
            }
            return (
              (priceBehavior === PriceBehaviourType.PayInAdvance ||
                priceBehavior === PriceBehaviourType.PayAsYouGo) &&
              !yearlyMeteredPrice
            );
          },
          then: function () {
            return Yup.object().required(
              'At least one price must be set if price behavior is not "included in plan"',
            );
          },
          otherwise: function () {
            if (pb && context.plan?.monthlyPrice) {
              return Yup.object().required(
                "Monthly price is required to match plan price intervals",
              );
            }

            return Yup.object().optional().nullable();
          },
        });
    }),
    yearlyMeteredPrice: Yup.lazy(() => {
      let pb: boolean = false;
      return Yup.object()
        .nullable()
        .when(["priceBehavior", "monthlyMeteredPrice"], {
          is: (priceBehavior: string, monthlyMeteredPrice: object) => {
            if (
              priceBehavior === PriceBehaviourType.PayInAdvance ||
              priceBehavior === PriceBehaviourType.PayAsYouGo
            ) {
              pb = true;
            }
            return pb && !monthlyMeteredPrice;
          },
          then: () => {
            return Yup.object().required(
              'At least one price must be set if price behavior is not "included in plan"',
            );
          },
          otherwise: () => {
            if (pb && context.plan?.yearlyPrice) {
              return Yup.object().required(
                "Yearly price is required to match plan price intervals",
              );
            }

            return Yup.object().optional().nullable();
          },
        });
    }),
  });
};

export const addMultipleEntitlementsValidationSchema = (
  context: EntitlementValidationContext,
) => {
  return Yup.object().shape({
    entitlements: Yup.array()
      .of(editEntitlementValidationSchema(context))
      .max(MAX_NUMBER_OF_CREATE_ENTITLEMENT),
  });
};
