import { TableLoader } from "@components/loaders/TableLoader";
import { useSchematicFeature } from "@hooks/useSchematicFeature";
import useTablePagination from "@hooks/useTablePagination";
import { ComponentResponseData, ListComponentsParams } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { FeaturesTableEmptyFilterState } from "@modules/features";
import { ColumnDef } from "@tanstack/react-table";
import { ButtonProps } from "@ui/Button";
import { DropdownDots } from "@ui/DropdownDots";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import pluralize from "pluralize";
import { SyntheticEvent, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ComponentsBlankState } from "./ComponentsBlankState";
import { ComponentDeleteOverlay } from "./overlays";
import { countComponents, listComponents } from "../queries";
import { timeAgo } from "@utils/date";
import { Pill } from "@components/ui/Pill";

export interface ComponentsTableProps {
  onCreate?: () => void;
}

export const ComponentsTable = ({ onCreate }: ComponentsTableProps) => {
  const navigate = useNavigate();
  const [filter] = useState<ListComponentsParams>({});

  const [deleteOverlay, setDeleteOverlay] =
    useState<ComponentResponseData | null>();

  const { canCreate, canEdit } = useSchematicFeature("components", {
    editPermission: ClerkUserPermission.components_edit,
    fallback: true,
  });

  const columns = useMemo<ColumnDef<ComponentResponseData>[]>(() => {
    return [
      {
        id: "name",
        header: "Name",
        accessorKey: "name",
      },
      // {
      //   id: "template",
      //   header: "Template",
      //   accessorKey: "template",
      //   size: 140,
      //   cell: (cellInfo) => {
      //     const { type } = cellInfo.row.original;
      //     return <div>{ComponentTypeDisplay[type as ComponentType]}</div>;
      //   },
      // },
      {
        id: "updatedAt",
        header: "Last edited",
        accessorKey: "updatedAt",
        size: 140,
        cell: (cellInfo) => {
          const { updatedAt } = cellInfo.row.original;

          return <div>{timeAgo(updatedAt)}</div>;
        },
      },
      {
        id: "actions",
        header: "",
        accessorKey: "actions",
        cell: (cellInfo) => {
          const component = cellInfo.row.original;

          return (
            <div className="flex flex-row items-end justify-end">
              <DropdownDots
                links={[
                  {
                    label: "Delete",
                    disabled: !canEdit,
                    onClick: () => {
                      setDeleteOverlay(component);
                    },
                  },
                  {
                    label: "Edit",
                    disabled: !canEdit,
                    onClick: () => {
                      navigate(component.id);
                    },
                  },
                ]}
              />
            </div>
          );
        },
      },
    ];
  }, [canEdit, navigate]);

  const getHeaderText = (count: number) => {
    return pluralize("Components", count, true);
  };

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<ComponentResponseData, ListComponentsParams>(
    ["components"],
    listComponents,
    countComponents,
    filter,
    getHeaderText,
  );

  const onRowClick = (row: ComponentResponseData, e?: SyntheticEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    navigate(row.id);
  };

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const headerButtons: ButtonProps[] = [
    {
      children: <>Create Component</>,
      color: "blue",
      disabled: !canCreate,
      onClick: onCreate,
    },
  ];

  const noComponentsCreated = countQuery?.data?.count === 0;
  const loading =
    listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderComponentsTable = () => {
    switch (true) {
      case noComponentsCreated:
        return (
          <ComponentsBlankState onCreate={onCreate} disabled={!canCreate} />
        );
      case loading:
        return <TableLoader />;
      case listQuery.data?.length === 0:
        return <FeaturesTableEmptyFilterState />;
      default:
        return (
          listQuery?.data && (
            <Table
              columns={columns}
              data={listQuery.data}
              onRowClick={onRowClick}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
            />
          )
        );
    }
  };

  const templates = [
    {
      title: "Plan Manager",
      description: "See current plan, add ons, and usage-based charges.",
      image: "/builder/templates/thumbs/plan-manager.jpg",
      avaliable: true,
    },
    {
      title: "Included Features",
      description:
        "See all features the user has access to with associated limits and usage",
      image: "/builder/templates/thumbs/included-features.jpg",
      avaliable: true,
    },
    {
      title: "Metered Features",
      description:
        "Detailed view of feature usage and limits with upgrade buttons.",
      image: "/builder/templates/thumbs/metered-features.jpg",
      avaliable: true,
    },
    {
      title: "Plans Table",
      description:
        "Provide an intuitive upgrade path by surfacing current and live plans.",
      image: "/builder/templates/thumbs/plans-table.jpg",
      avaliable: true,
    },
    {
      title: "Upcoming Bill",
      description:
        "See estimated upcoming bill based on current entitlements and usage.",
      image: "/builder/templates/thumbs/upcoming-bill.jpg",
      avaliable: true,
    },
    {
      title: "Invoices",
      description:
        "See a list of recent invoices sent to the user. Click to view detail.",
      image: "/builder/templates/thumbs/invoices.jpg",
      avaliable: true,
    },
    {
      title: "Payment Method",
      description: "See and easily edit current payment method on file.",
      image: "/builder/templates/thumbs/payment-method.jpg",
      avaliable: true,
    },
    {
      title: "Usage Graphs",
      description: "Show usage over time to surface usage trends.",
      image: "/builder/templates/thumbs/usage-graphs.jpg",
      avaliable: false,
    },
    {
      title: "Public Pricing Page",
      description:
        "Embed a fully feature pricing table on your marketing site.",
      image: "/builder/templates/thumbs/public-pricing-page.jpg",
      avaliable: false,
    },
  ];

  return (
    <div className="pb-16">
      {!noComponentsCreated && (
        <TableHeader headerText={headerText} buttons={headerButtons} />
      )}

      {renderComponentsTable()}

      <div className="mt-12">
        <div className="text-3xl mb-8">Element Library</div>
        <div className="grid grid-cols-3 gap-12">
          {templates.map((t, index) => {
            return (
              <div
                className="inline-flex flex-col min-w-[200px] overflow-hidden rounded-md bg-white shadow-md"
                key={index}
              >
                <div className="inline-block w-full border-b border-gray-400/40 max-h-[200px] overflow-hidden">
                  <img src={t.image} className="w-full" alt="" />
                </div>
                <div className="flex flex-col space-y-2 p-7 text-black">
                  <div className="flex flex-row justify-between items-center">
                    <h6 className="font-display m-0 text-[1.4rem] font-medium text-left !text-black">
                      {t.title}
                    </h6>

                    <Pill
                      text="normal"
                      type="rounded"
                      color={t.avaliable ? "blue" : "red"}
                      className="px-3"
                    >
                      {t.avaliable ? "Available" : "Coming soon"}
                    </Pill>
                  </div>
                  <p className="text-base text-gray-500">{t.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {deleteOverlay && (
        <ComponentDeleteOverlay
          component={deleteOverlay}
          onClose={() => {
            setDeleteOverlay(null);
          }}
        />
      )}
    </div>
  );
};
