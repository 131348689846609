import {
  CreateWebhookRequestBody,
  type WebhookEventDetailResponseData,
} from "@models/api";

export enum WebhookRequestType {
  CompanyUpdated = "company.updated",
  UserUpdated = "user.updated",
  PlanUpdated = "plan.updated",
  PlanEntitlementUpdated = "plan.entitlement.updated",
  CompanyOverrideUpdated = "company.override.updated",
  FeatureUpdated = "feature.updated",
  FlagUpdated = "flag.updated",
  FlagRulesUpdated = "flag_rules.updated",

  CompanyCreated = "company.created",
  UserCreated = "user.created",
  PlanCreated = "plan.created",
  PlanEntitlementCreated = "plan.entitlement.created",
  CompanyOverrideCreated = "company.override.created",
  FeatureCreated = "feature.created",
  FlagCreated = "flag.created",

  CompanyDeleted = "company.deleted",
  UserDeleted = "user.deleted",
  PlanDeleted = "plan.deleted",
  PlanEntitlementDeleted = "plan.entitlement.deleted",
  CompanyOverrideDeleted = "company.override.deleted",
  FeatureDeleted = "feature.deleted",
  FlagDeleted = "flag.deleted",

  SubscriptionTrialEnded = "subscription.trial.ended",
}

export enum WebhookStatus {
  Active = "active",
  Inactive = "inactive",
}

export type WebhookReq = CreateWebhookRequestBody & {
  status: WebhookStatus;
  requestTypes: WebhookRequestType[];
};

export enum WebhookEventStatus {
  Pending = "pending",
  Success = "success",
  Failed = "failure",
}

export type WebhookEvent = WebhookEventDetailResponseData & {
  status: WebhookEventStatus;
};
