import { TableLoader } from "@components/loaders/TableLoader";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { usePermission } from "@hooks/usePermission";
import useTablePagination from "@hooks/useTablePagination";
import { CompanyResponseData, ListCompanyOverridesParams } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { CompanyOverride } from "@models/entitlement";
import { Feature } from "@models/feature";
import { ListFilter } from "@models/req";
import {
  CompanyOverrideDeleteOverlay,
  CompanyOverrideOverlay,
  EntitlementCell,
} from "@modules/features";
import { CompanyOverridesTableBlankState } from "@modules/features/components/blank-states/CompanyOverridesTableBlankState";
import {
  countCompanyOverrides,
  deleteCompanyOverride,
  listCompanyOverrides,
} from "@modules/features/queries/companyOverrides";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { useQueryClient } from "@tanstack/react-query";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { Alert } from "@ui/Alert";
import { ButtonProps } from "@ui/Button";
import { DropdownDots } from "@ui/DropdownDots";
import { IconRound } from "@ui/Icon";
import { IconNameTypes } from "@ui/Icon/consts";
import { Logo } from "@ui/Logo";
import { PermissionButton } from "@ui/PermissionButton";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";

import { formatUTCDate, isDateBeforeCurrent } from "@utils/date";
import pluralize from "pluralize";
import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";

export interface CompanyOverridesTableProps {
  company?: CompanyResponseData;
  feature?: Feature;
}

export const CompanyOverridesTable = ({
  company,
  feature,
}: CompanyOverridesTableProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ListCompanyOverridesParams>({
    companyId: company?.id,
    featureId: feature?.id,
  });
  const [loading, setLoading] = useState(false);
  const [deleteOverride, setDeleteOverride] = useState<
    CompanyOverride | undefined
  >();
  const [editOverride, setEditOverride] = useState<
    CompanyOverride | undefined
  >();
  const [successAlert, setSuccessAlert] = useState("");
  const overrideEditAllowed = usePermission(ClerkUserPermission.overrides_edit);
  const timedOverrideFlag = useSchematicFlag("timed-override", {
    fallback: false,
  });

  const queryClient = useQueryClient();
  const navigate = useNavigateEnvironment();

  const columns = useMemo<ColumnDef<CompanyOverride>[]>(
    () => [
      // Reverse order because we want to show another entity
      ...(company
        ? [
            {
              id: "feature",
              header: "Feature",
              accessorKey: "feature",
              cell: (cellInfo: CellContext<CompanyOverride, unknown>) => {
                const feature = cellInfo.row.original.feature;
                if (!feature) return <></>;

                return (
                  <div className="flex flex-row items-center">
                    <IconRound name={feature.icon as IconNameTypes} size="sm" />
                    <div className="flex flex-col ml-3 space-y-1">
                      <div className="leading-none font-medium">
                        {feature.name}
                      </div>
                      <div className="leading-none text-gray-400">
                        {feature.id}
                      </div>
                    </div>
                  </div>
                );
              },
            },
          ]
        : []),
      ...(feature
        ? [
            {
              id: "company",
              header: "Company",
              accessorKey: "company.name",
              cell: (cellInfo: CellContext<CompanyOverride, unknown>) => {
                const company = cellInfo.row.original.company;
                const handleCompanyNavigate = (e: SyntheticEvent) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(`companies/${company?.id}`);
                };

                if (!company) return <></>;

                return (
                  <div className="flex flex-row items-center">
                    <div
                      onClick={handleCompanyNavigate}
                      className="hover:opacity-80 cursor-pointer transition-all"
                    >
                      <Logo src={company.logoUrl} alt={company.name} />
                    </div>
                    <div className="flex flex-col ml-3 space-y-1">
                      <div
                        className="leading-none font-medium hover:text-blue-400 cursor-pointer transition-all"
                        onClick={handleCompanyNavigate}
                      >
                        {company.name}
                      </div>
                      <div className="leading-none text-gray-400">
                        {company.id}
                      </div>
                    </div>
                  </div>
                );
              },
            },
          ]
        : []),
      {
        id: "entitlement",
        header: "Entitlement",
        accessorKey: "value",
        cell: (cellInfo) => {
          const entitlement = cellInfo.row.original;
          return (
            <EntitlementCell
              entitlement={entitlement}
              entity={entitlement.company}
            />
          );
        },
      },
      ...(timedOverrideFlag
        ? [
            {
              id: "expirationDate",
              header: "Expiry date",
              accessorKey: "expirationDate",
              cell: (cellInfo: CellContext<CompanyOverride, unknown>) => {
                const { expirationDate } = cellInfo.row.original;
                const alreadyExpired =
                  expirationDate && expirationDate < new Date();
                return (
                  <>
                    {expirationDate ? (
                      <span
                        className={`${alreadyExpired ? "text-red-600" : ""}`}
                      >
                        {formatUTCDate(expirationDate)}
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                );
              },
            },
          ]
        : []),
      {
        id: "actions",
        header: "",
        accessorKey: "actions",
        cell: (cellInfo) => {
          const override = cellInfo.row.original;
          return (
            <div className="flex flex-row items-end justify-end">
              <DropdownDots
                links={[
                  {
                    label: "Delete",
                    disabled: !overrideEditAllowed,
                    onClick: () => {
                      setSuccessAlert("");
                      setDeleteOverride(override);
                    },
                  },
                  {
                    label: "Edit",
                    disabled: !overrideEditAllowed,
                    onClick: () => {
                      setSuccessAlert("");
                      setEditOverride(override);
                    },
                  },
                  {
                    // Reverse order because we want to navigate to another entity
                    label: `View ${feature ? "company" : "feature"}`,
                    onClick: () =>
                      navigate(
                        feature
                          ? `companies/${override.companyId}/`
                          : `features/${override.featureId}/`,
                      ),
                  },
                ]}
              />
            </div>
          );
        },
      },
    ],
    [company, feature, navigate, overrideEditAllowed, timedOverrideFlag],
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const onDelete = async () => {
    if (!deleteOverride) return;

    setLoading(true);

    try {
      await deleteCompanyOverride(deleteOverride.id);

      await queryClient.invalidateQueries();
      setSuccessAlert("Override deleted successfully");

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    setFilter({
      companyId: company?.id,
      featureId: feature?.id,
      ...(searchTerm ? { q: searchTerm } : {}),
    });
  }, [company?.id, feature?.id, searchTerm]);

  const getHeaderText = (count: number) => {
    return pluralize("Company Overrides", count, true);
  };

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<
    CompanyOverride,
    ListCompanyOverridesParams & ListFilter
  >(
    ["company-overrides"],
    listCompanyOverrides,
    countCompanyOverrides,
    filter,
    getHeaderText,
  );

  const onRowClick = (row: CompanyOverride) => {
    setEditOverride(row);
  };

  const tableButtons: ButtonProps[] = [
    {
      children: <>Add company override</>,
      color: "blue",
      disabled: !overrideEditAllowed,
      onClick: () => setEditOverride({} as CompanyOverride),
    },
  ];

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const noCompanyOverrides = countQuery?.data?.count === 0;
  const isLoading =
    loading || listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderCompanyOverridesTable = () => {
    if (noCompanyOverrides) {
      return (
        <CompanyOverridesTableBlankState
          onCreate={() => setEditOverride({} as CompanyOverride)}
          disabled={!overrideEditAllowed}
        />
      );
    }

    if (isLoading) {
      return <TableLoader />;
    }

    return (
      listQuery?.data && (
        <Table
          columns={columns}
          data={listQuery.data}
          onRowClick={overrideEditAllowed ? onRowClick : undefined}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPagination={setPagination}
          rowStyleCallback={(row) =>
            timedOverrideFlag &&
            row.expirationDate &&
            isDateBeforeCurrent(row.expirationDate)
              ? "opacity-40 bg-gray-100"
              : ""
          }
          className="mt-8"
        />
      )
    );
  };

  return (
    <>
      {successAlert && (
        <div className="mb-6">
          <Alert style="green" size="xs" className="flex">
            {successAlert}
          </Alert>
        </div>
      )}

      {!isLoading && !noCompanyOverrides && (
        <TableHeader
          headerText={headerText}
          searchPlaceholder={"Find company override"}
          onSearch={handleSearch}
          className="pt-8 !pb-0"
        />
      )}

      {renderCompanyOverridesTable()}

      {!noCompanyOverrides && (
        <div className="mt-6 flex flex-row space-x-4">
          {tableButtons.map((b, index) => {
            return (
              <PermissionButton
                key={index}
                color={b.color}
                onClick={b.onClick}
                disabled={b.disabled}
              >
                {b.children}
              </PermissionButton>
            );
          })}
        </div>
      )}

      {deleteOverride &&
        createPortal(
          <CompanyOverrideDeleteOverlay
            override={deleteOverride}
            onClose={() => setDeleteOverride(undefined)}
            onDelete={onDelete}
          />,
          document.body,
        )}

      {editOverride && (
        <CompanyOverrideOverlay
          override={editOverride}
          onClose={() => setEditOverride(undefined)}
          feature={feature}
          company={company}
        />
      )}
    </>
  );
};
