import { BillingSubscriptionView } from "@models/api";
import { hasTrial } from "@utils/trials";
import { TrialPill } from "./TrialPill";

export const SubscriptionPriceDisplay = ({
  subscription,
}: {
  subscription?: BillingSubscriptionView;
}) => {
  const priceFormatter = (value: number): string => {
    const currency =
      subscription?.products[0]?.currency?.toUpperCase() ?? "USD";
    const priceInterval = subscription?.interval;
    const formatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency,
    }).format(value / 100);

    return `${formatted}/${priceInterval}`;
  };

  const price = subscription ? priceFormatter(subscription.totalPrice) : "n/a";

  const showTrialPill = hasTrial(subscription);
  return (
    <>
      <div className="text-sm">{price}</div>
      {showTrialPill && <TrialPill trialEnd={subscription?.trialEnd} />}
    </>
  );
};
