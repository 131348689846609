import { useEditor, useNode, type UserComponent } from "@craftjs/core";
import { Viewports } from "@modules/components/consts";
import { ViewportName, ViewportNameType } from "@modules/components/types";
import { Viewport as EmbedViewport } from "@schematichq/schematic-components";

interface ViewportProps {
  children?: React.ReactNode;
}

export const Viewport: UserComponent<ViewportProps> = ({ children }) => {
  const { rootElement } = useEditor((state) => {
    return {
      rootElement: state.nodes.ROOT.dom,
    };
  });

  const {
    connectors: { connect },
    width,
  } = useNode((node) => {
    const name: ViewportNameType = node.data.custom.name;
    const width = Viewports[name];
    return {
      width,
    };
  });

  return (
    <EmbedViewport
      ref={(ref) => connect(ref!)}
      className="layout viewport py-8 !gap-0"
      style={{ width }}
      portal={rootElement}
    >
      {children}
    </EmbedViewport>
  );
};

Viewport.craft = {
  displayName: "Viewport",
  custom: {
    name: ViewportName.Tablet,
  },
  rules: {
    canDrag: () => false,
    canDrop: () => false,
  },
};
