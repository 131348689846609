import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { errorMessage } from "@data/index";
import { listIntegrations } from "@data/integrations";
import { FormikControl } from "@forms/FormikControl";
import { FormikStep, FormikStepper } from "@forms/FormikStepper";
import { useContextQuery } from "@hooks/useContextQuery";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import {
  type BillingPriceResponseData,
  BillingPriceView,
  BillingProductResponseData,
} from "@models/api";
import { PriceBehaviourType } from "@models/entitlement";
import { Feature } from "@models/feature";
import { Plan, PlanIcon, PlanReq, PlanType } from "@models/plan";
import {
  createPlan,
  updatePlan,
  updatePlanBillingProduct,
} from "@modules/plans";
import { PlanRoutePaths } from "@modules/plans/consts";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { randomDiamond } from "@ui/Diamond/consts";
import { FormColumn, FormHeader, FormRow } from "@ui/FormParts";
import { LabeledTooltip } from "@ui/LabeledTooltip";
import { Overlay, OverlayModal } from "@ui/Overlay";
import { OverlayFormAlert } from "@ui/OverlayFormAlert";
import { DropdownIcons } from "@ui/SelectIcons";

import { titlecase } from "@utils/strings";
import { FormikHelpers, FormikProps } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { PlanEditBillingStep } from "./PlanEditBillingStep";
import { PlanEditTrialStep } from "./PlanEditTrialStep";

export interface PlanEditOverlayProps {
  onClose: () => void;
  plan?: Plan;
  initialStep?: number;
  type: PlanType;
  isFree?: boolean;
}

export type UsageBasedEntitlements = {
  feature: Feature;
  meteredMonthlyPrice?: BillingPriceView;
  meteredYearlyPrice?: BillingPriceView;
  priceBehavior: PriceBehaviourType;
};

type PlanValues = PlanReq & {
  billingProduct: BillingProductResponseData;
  billingProductId: string;
  isTrialable: boolean;
  monthlyPrice: BillingPriceResponseData;
  monthlyPriceId: string;
  yearlyPrice: BillingPriceResponseData;
  yearlyPriceId: string;
  trialDays: number;
  isFree: boolean;
  usageBased: boolean;
};

const defineStepValidationSchema = Yup.object({
  name: Yup.string().required("Must provide a name"),
  description: Yup.string(),
  icon: Yup.string(),
});

const billingStepValidationSchema = Yup.object().shape(
  {
    isFree: Yup.boolean(),
    usageBased: Yup.boolean(),
    billingProductId: Yup.string(),
  },
  [],
);

export const PlanEditOverlay = ({
  onClose,
  plan,
  initialStep = 0,
  type,
}: PlanEditOverlayProps) => {
  const navigate = useNavigateEnvironment();
  const queryClient = useQueryClient();
  const billingFlag = useSchematicFlag("billing", { fallback: true });
  const trialFlag = useSchematicFlag("trials", { fallback: false });

  const verb = plan?.id ? "Edit" : "Create";
  const noun = type === PlanType.Plan ? "plan" : "add on";

  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [closeAlert, setCloseAlert] = useState(false);
  const [dirty, setDirty] = useState(false);

  const initialValues = {
    description: plan?.description || "",
    name: plan?.name || "",
    usageBased: false,
    id: plan?.id,
    usageBasedEntitlements: [],
    isFree: plan?.isFree ?? true,
    usageBasedPriceBehavior: "pay_as_you_go",
    planType: (plan?.planType || type) as PlanType,
    icon: (plan?.icon || randomDiamond()) as PlanIcon,
    isTrialable: plan?.isTrialable,
    billingProduct: plan?.billingProduct,
    billingProductId: plan?.billingProduct?.productId,
    monthlyPrice: plan?.monthlyPrice,
    monthlyPriceId: plan?.monthlyPrice?.id,
    yearlyPrice: plan?.yearlyPrice,
    yearlyPriceId: plan?.yearlyPrice?.id,
    trialDays: plan?.trialDays,
  } as PlanValues;

  const onSubmit = async (
    {
      billingProduct,
      billingProductId,
      isTrialable,
      monthlyPrice,
      monthlyPriceId,
      isFree,
      yearlyPrice,
      yearlyPriceId,
      usageBased,
      trialDays,
      ...req
    }: PlanValues,
    helpers: FormikHelpers<PlanValues>,
  ) => {
    setLoading(true);

    const saveFn = plan?.id
      ? (values: PlanReq) => updatePlan(plan.id, values)
      : createPlan;

    const onSuccessFn = plan?.id
      ? onClose
      : (createdPlan: Plan) =>
          navigate(
            `${
              type === PlanType.Plan
                ? PlanRoutePaths.Plans
                : PlanRoutePaths.AddOns
            }/${createdPlan.id}`,
          );

    try {
      const plan = await saveFn(req);

      await updatePlanBillingProduct(plan.id, {
        billingProductId,
        monthlyPriceId,
        yearlyPriceId,
        isTrialable: isTrialable,
        trialDays: trialDays,
        isFreePlan: isFree,
      });

      await queryClient.invalidateQueries();
      setApiError(undefined);
      onSuccessFn(plan);
      setLoading(false);
    } catch (err) {
      setApiError(errorMessage(err));
      setLoading(false);
      helpers.setSubmitting(false);
    }
  };

  const handleClose = () => (dirty ? setCloseAlert(true) : onClose());

  const { data: integrationsData } = useContextQuery({
    queryKey: ["integrations", "stripe"],
    queryFn: async () => {
      try {
        return await listIntegrations({ type: "stripe" });
      } catch (error: any) {
        // TODO: Fix integrations returning 500
        if (error.responseCode <= 500) {
          return [];
        }

        throw error;
      }
    },
    retry: (failureCount, error: any) => {
      // TODO: Fix integrations returning 500
      if (error.responseCode <= 500) {
        return false;
      }

      return failureCount < 3;
    },
  });

  const stripeConnected = integrationsData?.[0]?.state;

  return (
    <Overlay
      onClose={handleClose}
      className="flex items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader />}
      {closeAlert && (
        <OverlayFormAlert setDirtyForm={setCloseAlert} onClose={onClose} />
      )}
      <OverlayModal size="xl" className="min-w-[1000px]">
        <FormikStepper
          className="flex-1 w-full"
          onSubmit={onSubmit}
          onClose={handleClose}
          innerRef={(formikActions: FormikProps<PlanValues>) => {
            formikActions && setDirty(formikActions.dirty);
          }}
          dirty={dirty}
          editMode={!!plan?.id}
          initialValues={initialValues}
          initialStep={initialStep}
          expand
        >
          <FormikStep
            label="Define"
            validationSchema={defineStepValidationSchema}
          >
            <LabeledTooltip
              label="All Environments"
              description={`${titlecase(noun)} exist in all environments`}
              className="top-10 right-16"
              position="absolute"
              placement="bottom-center"
            />

            <FormHeader
              label={`${verb} ${noun}`}
              title={`Define ${titlecase(noun)}`}
              description={
                type === PlanType.Plan
                  ? "A company can only have one base plan."
                  : "A company can have multiple add ons. Add ons can only have boolean entitlements."
              }
            />

            <FormColumn>
              <FormRow>
                <DropdownIcons
                  name="icon"
                  label="Icon"
                  sets={{ diamonds: true }}
                />
                <div className="flex-1">
                  <FormikControl
                    control="input"
                    name="name"
                    type="text"
                    label="Name"
                    placeholder="Enter name"
                    description={`A human-friendly name for your ${noun}.`}
                  />
                </div>
              </FormRow>

              <FormikControl
                control="input"
                name="description"
                type="text"
                label="Description"
                placeholder="Enter description"
                description="Optional"
              />
              {apiError && (
                <div className="px-2">
                  <Alert size="xs" style="red">
                    <div className="flex items-center justify-center space-x-2">
                      <div className="text-base font-body ">
                        <span className="font-semibold">Uh-oh!</span> {apiError}
                      </div>
                    </div>
                  </Alert>
                </div>
              )}
            </FormColumn>
          </FormikStep>

          {billingFlag && (
            <FormikStep
              label="Billing"
              validationSchema={billingStepValidationSchema}
            >
              <PlanEditBillingStep
                noun={noun}
                verb={verb}
                apiError={apiError}
                planId={plan?.id}
              />
            </FormikStep>
          )}
          {billingFlag && trialFlag && noun == "plan" && (
            <FormikStep label="Trial">
              <PlanEditTrialStep
                verb={verb}
                stripeConnected={stripeConnected}
                billingProduct={plan?.billingProduct}
              />
            </FormikStep>
          )}
        </FormikStepper>
      </OverlayModal>
    </Overlay>
  );
};
