import {
  RedirectToSignIn,
  SignedIn,
  SignedOut,
  useAuth,
  useOrganizationList,
  useUser,
} from "@clerk/clerk-react";
import { useAnalytics } from "@contexts/AnalyticsContext";
import { EnvironmentContext } from "@contexts/EnvironmentContext";
import { FullStory } from "@fullstory/browser";
import {
  useSchematicContext,
  useSchematicEvents,
} from "@schematichq/schematic-react";
import * as Sentry from "@sentry/react";
import { ErrorBoundaryFallback } from "@ui/ErrorBoundaryFallback";
import { Navigation } from "@ui/Navigation";
import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useValidEnvironment } from "./use-valid-environment";

export const ProtectedComponent: React.FC = () => {
  const location = useLocation();

  const { track } = useSchematicEvents();
  const { setContext } = useSchematicContext();
  const { environmentId } = useContext(EnvironmentContext);
  const segmentClient = useAnalytics();

  useValidEnvironment();

  // We must have a logged-in Clerk user to proceed.
  // If this Clerk user does not have an organization associated, they can't
  // use the app, so we just want to redirect them to our waitlist signup page.
  const { isLoaded, isSignedIn, user } = useUser();
  const { setActive } = useOrganizationList();
  const {
    actor: impersonationActor,
    isLoaded: isAuthLoaded,
    orgId,
  } = useAuth();

  useEffect(() => {
    if (isLoaded && user && user.organizationMemberships.length === 0) {
      window.location.href = "https://schematichq.com/waiting-room";
    }
  }, [isLoaded, user]);

  useEffect(() => {
    if (!isLoaded || !isSignedIn || !user || !track || !!impersonationActor)
      return;

    const clerkOrg = user?.organizationMemberships[0]?.organization;
    if (!clerkOrg) return;

    segmentClient?.page(location.pathname, {
      path: location.pathname,
      url: location.toString(),
      properties: {
        accountName: clerkOrg.name,
        clerkOrganizationId: clerkOrg.id,
      },
    });

    track({
      company: { clerkId: clerkOrg.id },
      event: "pageview",
      traits: { path: location.pathname },
      user: { clerkId: user.id },
    });
  }, [
    impersonationActor,
    isLoaded,
    isSignedIn,
    location,
    track,
    user,
    segmentClient,
  ]);

  useEffect(() => {
    if (
      setContext &&
      isLoaded &&
      user &&
      user.organizationMemberships.length > 0
    ) {
      const context = {
        company: {
          clerkId: user.organizationMemberships[0]?.organization.id,
        },
        user: {
          clerkId: user.id,
        },
      };
      void setContext(context);
    }
  }, [isLoaded, user, setContext]);

  useEffect(() => {
    if (
      isAuthLoaded &&
      !orgId &&
      setActive &&
      user &&
      user.organizationMemberships.length > 0
    ) {
      void setActive({
        organization: user.organizationMemberships[0]?.organization.id,
      });
    }
  }, [isAuthLoaded, orgId, setActive, user]);

  if (!isLoaded) {
    return null; // TOO: Add a loading spinner here
  }

  if (import.meta.env.VITE_FULLSTORY_ORG && user) {
    try {
      FullStory("setIdentity", {
        uid: user.id,
        properties: {
          displayName: user.fullName ?? undefined,
          email: user.emailAddresses[0]?.emailAddress,
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <SignedIn>
        <main>
          <div className="">
            <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Navigation />
              <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
                {environmentId && <Outlet />}
              </Sentry.ErrorBoundary>
            </Sentry.ErrorBoundary>
          </div>
        </main>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
};
