import { EntityTraitLabel } from "@components/ui/RuleBlock/EntityTraitLabel";
import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import { EntityTraitType, EntityType } from "@models/entityTrait";
import { Feature } from "@models/feature";
import {
  createEntityTraitDefinition,
  listEntityTraitDefinitions,
} from "@modules/settings/queries/entityTraits";
import { FormColumn, FormHeader } from "@ui/FormParts";
import { useFormikContext } from "formik";
import slugify from "slugify";

interface FeatureOverlayTraitStepProps {
  verb: string;
  feature?: Feature;
}

export const FeatureOverlayTraitStep = ({
  verb,
  feature,
}: FeatureOverlayTraitStepProps) => {
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <FormHeader
        label={`${verb} feature`}
        title="Assign trait"
        description={
          <>
            Add a trait to track the usage of this feature. Only numeric traits
            can be selected for feature limits.{" "}
          </>
        }
      />
      <FormColumn>
        <FormikAsyncSelect
          placeholder="Start typing to search for or add a new trait..."
          defaultOptions
          label="Search for traits"
          loadOptions={listEntityTraitDefinitions}
          loadOptionsMappers={{
            requestFilter: {
              traitType: EntityTraitType.Number,
              entityType: EntityType.Company,
            },
            mapperFunction: (trait) => ({
              entity: trait,
              value: trait.id,
              label: <EntityTraitLabel entityTrait={trait} />,
            }),
            resultsFilter: (trait) => !!trait.id,
          }}
          name="traitId"
          nullable
          creatable
          creatableLabel="Trait"
          selectedOption={
            feature?.trait && {
              entity: feature.trait,
              value: feature.trait.id,
              label: <EntityTraitLabel entityTrait={feature.trait} />,
            }
          }
          onCreate={async (option: string) => {
            const newEntityTraitDefinition = await createEntityTraitDefinition({
              displayName: option,
              entityType: EntityType.Company,
              hierarchy: [slugify(option)],
              traitType: EntityTraitType.Number,
            });

            return {
              entity: newEntityTraitDefinition,
              value: newEntityTraitDefinition.id,
              label: (
                <EntityTraitLabel entityTrait={newEntityTraitDefinition} />
              ),
            };
          }}
          onChange={(option: Option) => {
            if (option.value.entity) {
              setFieldValue("traitId", option.value.entity);
            } else {
              setFieldValue("traitId", "");
            }
          }}
        />
      </FormColumn>
    </>
  );
};
