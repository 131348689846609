import { AccountEnvironment } from "@models/account";
import { RuleDetailResponseDataWithoutConditions } from "@modules/features/components/tabs/FeatureFlagTargetingTab/FeatureFlagTargetingTab";
import * as Tooltip from "@radix-ui/react-tooltip";
import { ButtonGroup } from "@ui/ButtonGroup";
import { FormikErrors } from "formik";

import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { ReviewOverlay } from "./ReviewOverlay";

export type ApiResponse<T> = {
  success: boolean;
  errors: string[];
  response: T | null;
};

interface PageNotificationProps<T> {
  changes: ReactNode[];
  environment?: AccountEnvironment;
  apiResponse: ApiResponse<T>;
  loading: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  onResponse: (response: T | null) => void;
  children: ReactNode;
  disabled?: boolean;
  errors?: string | string[] | FormikErrors<any>[];
}

export const PageNotification = <T,>({
  changes,
  environment,
  apiResponse,
  loading,
  onCancel,
  onSubmit,
  onResponse,
  children,
  disabled,
  errors,
}: PageNotificationProps<T>) => {
  const [review, setReview] = useState(false);

  useEffect(() => {
    const { success, response } = apiResponse;
    if (success) {
      handleClose();
      onResponse(response);
    }
  }, [apiResponse, onResponse]);

  const onReview = () => {
    setReview(true);
  };

  const handleClose = () => {
    setReview(false);
  };

  let numberOfErrors = 0;
  if (Array.isArray(errors)) {
    numberOfErrors = errors.length;
  } else if (errors) {
    numberOfErrors = 1;
  }

  return createPortal(
    <div className="bg-blue-50 px-28 py-4 animate-in">
      <div className="tw-wrapper">
        <div className="flex justify-between items-center">
          <div className="text-lg font-medium">
            {changes.length} changes made
          </div>
          <div className="flex space-x-2 items-center">
            {environment && (
              <div className="text-xs uppercase font-bold text-gray-500 mr-2">
                {environment.name}
              </div>
            )}
            <ButtonGroup
              buttons={[
                {
                  children: <>Cancel</>,
                  color: "white",
                  onClick: onCancel,
                  size: "md",
                },
                {
                  children: numberOfErrors ? (
                    <>
                      <Tooltip.Provider delayDuration={0}>
                        <Tooltip.Root>
                          <Tooltip.Trigger asChild>
                            <div>Review and save</div>
                          </Tooltip.Trigger>
                          <Tooltip.Portal>
                            <Tooltip.Content
                              side="bottom"
                              sideOffset={15}
                              className="bg-white p-4 rounded-md shadow-md w-64"
                            >
                              {numberOfErrors} fields invalid, please correct
                              before saving rule set
                              <Tooltip.Arrow className="fill-white" />
                            </Tooltip.Content>
                          </Tooltip.Portal>
                        </Tooltip.Root>
                      </Tooltip.Provider>
                    </>
                  ) : (
                    <>Review and save</>
                  ),
                  color: "blue",
                  onClick: onReview,
                  disabled,
                  size: "md",
                },
              ]}
            />
          </div>
        </div>
      </div>

      {review && (
        <ReviewOverlay
          environment={environment}
          onClose={handleClose}
          onSubmit={onSubmit}
          errors={apiResponse?.errors}
          loading={loading}
          children={children}
        />
      )}
    </div>,
    document.getElementById("portal-notifications")!,
  );
};

export function compareRuleArrays(
  initialRules: RuleDetailResponseDataWithoutConditions[],
  currentRules: RuleDetailResponseDataWithoutConditions[],
): ReactNode[] {
  const changes: ReactNode[] = [];

  const initialMap = new Map(
    initialRules.map((rule) => [rule.id || rule.name, rule]),
  );
  const currentMap = new Map(
    currentRules.map((rule) => [rule.id || rule.name, rule]),
  );

  for (const [ruleKey, initialRule] of initialMap) {
    if (!currentMap.has(ruleKey)) {
      changes.push(
        <div>
          Rule <span className="font-semibold">{initialRule.name}</span> was
          removed.
        </div>,
      );
    } else {
      const currentRule = currentMap.get(ruleKey);
      if (!currentRule) {
        continue;
      }

      if (initialRule.name !== currentRule.name) {
        changes.push(
          <div>
            Rule <span className="font-semibold">{initialRule.name}</span>{" "}
            renamed to <span className="font-semibold">{currentRule.name}</span>
            .
          </div>,
        );
      }

      if (initialRule.value !== currentRule.value) {
        changes.push(
          <div>
            Rule <span className="font-semibold">{currentRule.name}</span> value
            changed from{" "}
            <span className="font-semibold">
              {initialRule.value ? "true" : "false"}
            </span>{" "}
            to{" "}
            <span className="font-semibold">
              {currentRule.value ? "true" : "false"}
            </span>
            .
          </div>,
        );
      }

      if (
        JSON.stringify(initialRule.conditionGroups) !==
        JSON.stringify(currentRule.conditionGroups)
      ) {
        changes.push(
          <div>
            Conditions for rule{" "}
            <span className="font-semibold">{currentRule.name}</span> changed.
          </div>,
        );
      }
    }
  }

  for (const ruleKey of currentMap.keys()) {
    const currentRule = currentMap.get(ruleKey);
    if (!currentRule) {
      continue;
    }

    if (!initialMap.has(ruleKey)) {
      changes.push(
        <div>
          Rule <span className="font-semibold">{currentRule.name}</span> was
          added.
        </div>,
      );
    }
  }

  return changes;
}
