import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { errorMessage, FetchError } from "@data/index";
import { FormikControl } from "@forms/FormikControl";
import { FormikStep, FormikStepper } from "@forms/FormikStepper";
import {
  CompanyDetailResponseData,
  type EntityKeyDetailResponseData,
  UpsertCompanyRequestBody,
} from "@models/api";
import { EntityType } from "@models/entityKey";
import { CompanyOrCompanyUserEditOverlayKeyStep } from "@modules/companies/components/overlays/CompanyOrCompanyUserEditOverlayKeyStep";
import {
  createCompany,
  lookupCompany,
  updateCompany,
} from "@modules/companies/queries";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { FormColumn, FormHeader, FormRow } from "@ui/FormParts";
import { Overlay, OverlayModal } from "@ui/Overlay";
import { OverlayFormAlert } from "@ui/OverlayFormAlert";
import { FormikHelpers } from "formik";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

type CompanyEditOverlayProps = {
  onClose: () => void;
  company?: CompanyDetailResponseData;
};

export type CompanyFormValues = {
  name: string;
  keys: EntityKeyDetailResponseData[];
  url: string;
};

const submitUpdate = async (
  companyId: string,
  reqData: UpsertCompanyRequestBody,
) => {
  try {
    const lookupCompanyResult = await lookupCompany({
      keys: reqData.keys,
    });

    if (lookupCompanyResult && lookupCompanyResult.id !== companyId) {
      throw new Error("Trying to update another company with same keys.");
    }
  } catch (err: any) {
    if (err.responseCode !== 404) {
      throw new FetchError(err.message);
    }
  }

  return updateCompany(companyId, reqData);
};

const submitCreate = async (reqData: UpsertCompanyRequestBody) => {
  try {
    const lookupCompanyResult = await lookupCompany({
      keys: reqData.keys,
    });

    if (lookupCompanyResult) {
      throw new Error("Company with specified keys already exists.");
    }
  } catch (err: any) {
    if (err.responseCode !== 404) {
      throw new FetchError(err.message);
    }
  }

  return createCompany(reqData);
};

export const CompanyEditOverlay = ({
  onClose,
  company,
}: CompanyEditOverlayProps) => {
  const queryClient = useQueryClient();
  const verb = company?.id ? "Edit" : "Create";
  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [closeAlert, setCloseAlert] = useState(false);
  const [dirty, setDirty] = useState(false);

  const initialValues: CompanyFormValues = {
    name: company?.name || "",
    url:
      "url" in (company?.traits ?? {})
        ? (company?.traits as { url: string }).url
        : "",
    keys: (company?.keys || []).map((key) => ({ ...key, uuid: uuidv4() })),
  };

  const onSubmit = async (
    formValues: CompanyFormValues,
    helpers: FormikHelpers<CompanyFormValues>,
  ) => {
    setLoading(true);

    try {
      const reqData = {
        keys: formValues.keys.reduce((acc, key) => {
          return {
            ...acc,
            [key.key]: key.value,
          };
        }, {}),
        name: formValues.name,
        traits: {
          url: formValues.url,
        },
      };

      if (company?.id) {
        await submitUpdate(company.id, reqData);
      } else {
        await submitCreate(reqData);
      }

      await queryClient.invalidateQueries();
      setApiError(undefined);
      onClose();
      setLoading(false);
    } catch (err) {
      setApiError(errorMessage(err));
      setLoading(false);
      helpers.setSubmitting(false);
    }
  };

  const handleClose = () => (dirty ? setCloseAlert(true) : onClose());

  return (
    <Overlay
      onClose={handleClose}
      className="flex items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader />}
      {closeAlert && (
        <OverlayFormAlert setDirtyForm={setCloseAlert} onClose={onClose} />
      )}
      <OverlayModal size="xl">
        <FormikStepper
          className="flex-1 w-full"
          onSubmit={onSubmit}
          onClose={handleClose}
          innerRef={(formikActions: any) => {
            formikActions && setDirty(formikActions.dirty);
          }}
          dirty={dirty}
          editMode={!!company?.id}
          initialValues={initialValues}
        >
          <FormikStep
            label="Define"
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required"),
              url: Yup.string(),
            })}
          >
            <FormHeader
              label={`${verb} company`}
              title="Define company"
              description="Give the company name and URL"
            />

            <FormColumn>
              <FormRow>
                <div className="flex-1">
                  <FormikControl
                    control="input"
                    name="name"
                    type="text"
                    label="Name"
                    placeholder="Enter company name"
                    description="A human-friendly name for your company"
                  />
                </div>
              </FormRow>

              <FormRow>
                <div className="flex-1">
                  <FormikControl
                    control="input"
                    name="url"
                    type="text"
                    label="URL"
                    placeholder="Enter URL"
                    description="This is used to populate company logo"
                  />
                </div>
              </FormRow>
            </FormColumn>
          </FormikStep>

          <FormikStep
            label="Keys"
            validationSchema={Yup.object().shape({
              keys: Yup.array()
                .min(1)
                .of(
                  Yup.object().shape({
                    id: Yup.string().required("Key is required"),
                    value: Yup.string().required("Value is required"),
                  }),
                ),
            })}
          >
            <CompanyOrCompanyUserEditOverlayKeyStep
              label={`${verb} company`}
              entityType={EntityType.Company}
            />

            {apiError && (
              <div className="px-2 mt-4">
                <Alert size="xs" style="red">
                  <div className="flex items-center justify-center space-x-2">
                    <div className="text-base font-body ">
                      <span className="font-semibold">Uh-oh!</span> {apiError}
                    </div>
                  </div>
                </Alert>
              </div>
            )}
          </FormikStep>
        </FormikStepper>
      </OverlayModal>
    </Overlay>
  );
};
