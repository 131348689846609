import cx from "classnames";
import { ReactNode } from "react";
import { Icon } from "../Icon";

export type LabeledTooltipPlacementTypes =
  | "top-left"
  | "top-right"
  | "bottom-left"
  | "bottom-right"
  | "top-center"
  | "bottom-center"
  | "right-center"
  | "left-center";

export type LabeledTooltipSizeTypes = "sm" | "md" | "lg";

export interface LabeledTooltipProps {
  label?: string | ReactNode;
  description: string | ReactNode;
  placement?: LabeledTooltipPlacementTypes;
  size?: LabeledTooltipSizeTypes;
  position?: "relative" | "absolute";
  className?: string;
  noIcon?: boolean;
}

export const LabeledTooltip = ({
  label,
  description,
  placement = "top-center",
  size = "md",
  position = "relative",
  noIcon,
  className,
}: LabeledTooltipProps) => {
  const tooltipPlacementMap = {
    "top-left": "bottom-0 right-[104%]",
    "top-right": "bottom-0 left-[104%]",
    "bottom-left": "top-0 right-[104%]",
    "bottom-right": "top-0 left-[104%]",
    "top-center": "bottom-[104%] left-[50%] translate-x-[-50%]",
    "bottom-center": "top-[104%] left-[50%] translate-x-[-50%]",
    "right-center": "left-[104%]",
    "left-center": "right-[104%]",
  };

  const tooltipSizeMap = {
    sm: "w-[160px]",
    md: "w-[270px]",
    lg: "w-[390px]",
  };

  const tooltipStyles = cx(
    "invisible opacity-0",
    "group-hover:visible z-30 group-hover:opacity-100 transition-all leading-1 border border-gray-200 shadow-lg text-sm bg-white px-5 py-2.5 font-medium rounded-lg absolute",
    tooltipSizeMap[size],
    tooltipPlacementMap[placement],
  );

  const tooltipPositionMap = {
    relative: "relative",
    absolute: "absolute",
  };

  return (
    <div
      className={cx(
        "inline-flex z-40 items-center bg-white rounded-md space-x-1 group hover:cursor-help",
        tooltipPositionMap[position],
        className,
      )}
    >
      {label && (
        <div className="text-sm group-hover:opacity-80 transition-all">
          {label}
        </div>
      )}

      {!noIcon && (
        <Icon
          name="info-rounded"
          className="text-blue-400 relative text-2xl leading-none group-hover:opacity-80 transition-all"
        />
      )}
      <div className={tooltipStyles}>{description}</div>
    </div>
  );
};
