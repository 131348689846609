import { Elevate } from "@ui/Elevate";

export const ConnectStripeCell = () => {
  return (
    <Elevate className="flex flex-col">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <div className="text-xl">
            Connect Stripe to unlock plan life cycle management, and more.
          </div>
          <div className="text-xs mt-2">
            A Stripe product must be associated with this plan to enable trials.
          </div>
        </div>
        <div className="pr-8">
          <img
            alt="Connect Stripe to Schematic"
            src="/connect-stripe.png"
            className="max-h-36"
          />
        </div>
      </div>
    </Elevate>
  );
};
