import { EntityTraitLabel } from "@components/ui/RuleBlock/EntityTraitLabel";
import { Separator } from "@components/ui/Separator";
import { listBillingPrices } from "@data/billing";
import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import { FormikControl } from "@forms/FormikControl";
import { FormikSelect } from "@forms/FormikSelect";
import { useStripeIntegration } from "@hooks/useStripeIntegration";
import {
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum,
  CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum,
  EntityTraitDefinitionResponseData,
} from "@models/api";
import { BillingPriceDetailResponseData } from "@models/api/models/BillingPriceDetailResponseData";
import { EntitlementValueType } from "@models/entitlement";
import { EntityTraitType, EntityType } from "@models/entityTrait";
import {
  ConditionMetricPeriodDisplay,
  ConditionMetricPeriodMonthResetDisplay,
  ConditionMetricPeriods,
  ConditionMetricPeriodMonthResets,
  FeatureType,
} from "@models/feature";
import { FeatureTypeCell } from "@modules/features/components/FeatureTypeCell";
import { MetricPeriodDisplay } from "@modules/plans/consts";
import { listEntityTraitDefinitions } from "@modules/settings/queries/entityTraits";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { Alert } from "@ui/Alert";
import { FormColumn, FormRow } from "@ui/FormParts";
import { IconRound } from "@ui/Icon";
import { IconNameTypes } from "@ui/Icon/consts";
import { Switch } from "@ui/Switch";
import { formatCurrency } from "@utils/strings";
import { FormikErrors } from "formik";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import { FormValues } from "./PlanEntitlementEditOverlay";

type EntitlementFeatureBlockProps = {
  values: any;
  name?: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<any>>;
};

const featureBillingTypes: Option[] = [
  {
    label: "Included in plan",
    value: "included",
  },
  {
    label: "Pay as you go",
    value: "pay_as_you_go",
  },
  {
    label: "Pay in advance",
    value: "pay_in_advance",
  },
];

const UsageBasedPriceOptionLabel = ({
  prop,
}: {
  prop: BillingPriceDetailResponseData;
}) => {
  return (
    <div className={"flex flex-row gap-2 justify-between p-2"}>
      <div className={"font-semibold text-sm"}>{prop.productName}</div>
      <div className={"text-sm"}>
        {formatCurrency(prop.price, prop.currency)}/per unit/{prop.interval}
      </div>
    </div>
  );
};

export const EntitlementFeatureBlock = ({
  values,
  name = "",
  setFieldValue,
}: EntitlementFeatureBlockProps) => {
  const [isPaidEntitlement, setIsPaidEntitlement] = useState<
    boolean | undefined
  >();
  const [usageType, setUsageType] = useState<Option | undefined>();

  const { hasStripeIntegration } = useStripeIntegration();

  const stripeIntegrationFlag = useSchematicFlag("stripe-integration-flag", {
    fallback: true,
  });

  const usageBasedPricesFlag = useSchematicFlag("billing.usagebased", {
    fallback: false,
  });

  useEffect(() => {
    if (!usageBasedPricesFlag) {
      return;
    }

    if (usageType) {
      if (usageType.value === "included") {
        setIsPaidEntitlement(false);
        setFieldValue(`${name}priceBehavior`, null);
        setFieldValue(`${name}monthlyMeteredPrice`, null);
        setFieldValue(`${name}yearlyMeteredPrice`, null);
        setFieldValue(`${name}yearlyMeteredPriceId`, null);
        setFieldValue(`${name}monthlyMeteredPriceId`, null);
      } else {
        setIsPaidEntitlement(true);
        setFieldValue(`${name}priceBehavior`, usageType.value);
      }

      if (usageType.value === "pay_as_you_go") {
        setFieldValue(`${name}metricPeriodMonthReset`, "billing_cycle");
        setFieldValue(`${name}valueType`, EntitlementValueType.Unlimited);
        setFieldValue(`${name}metricPeriod`, "current_month");
      }

      if (usageType.value === "pay_in_advance") {
        setFieldValue(`${name}metricPeriodMonthReset`, "billing_cycle");
        setFieldValue(`${name}valueType`, EntitlementValueType.Trait);
        setFieldValue(`${name}metricPeriod`, "current_month");
      }
    } else {
      setUsageType(featureBillingTypes.find((fb) => fb.value === "included"));
    }
  }, [usageType]);

  useEffect(() => {
    if (!usageBasedPricesFlag) {
      return;
    }

    if (values.priceBehavior) {
      const priceOption = featureBillingTypes.find(
        (option) => option.value === values.priceBehavior,
      );
      setUsageType(priceOption);
    } else if (!values.priceBehavior && usageType === undefined) {
      const priceOption = featureBillingTypes.find(
        (option) => option.value === "included",
      );
      setUsageType(priceOption);
      setFieldValue("priceBehavior", "included");
      setIsPaidEntitlement(false);
    }
  }, [values]);

  const isBillableAssociationSupported = () => {
    if (!usageBasedPricesFlag) {
      return false;
    }

    if (!hasStripeIntegration) {
      return false;
    }

    return values.valueType !== EntitlementValueType.Boolean;
  };

  const metricPeriodOptions = ConditionMetricPeriods.map((period) => {
    return {
      value: period,
      label: ConditionMetricPeriodDisplay[period],
    };
  });

  const metricPeriodMonthResetOptions = ConditionMetricPeriodMonthResets.filter(
    (period) => {
      if (
        period ===
        CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum.BillingCycle
      ) {
        return stripeIntegrationFlag;
      }
      return true;
    },
  ).map((period) => {
    return {
      value: period,
      label: ConditionMetricPeriodMonthResetDisplay[period],
    };
  });

  const loadOptionsMappers = {
    requestFilter: {
      entityType: EntityType.Company,
      traitType: EntityTraitType.Number,
    },
    mapperFunction: (trait: EntityTraitDefinitionResponseData) => ({
      value: trait,
      label: <EntityTraitLabel entityTrait={trait} />,
    }),
    resultsFilter: (trait: EntityTraitDefinitionResponseData) => !!trait.id,
  };

  const valueToText = (values: FormValues) => {
    if (!values.feature) return "";

    if (values.valueType === EntitlementValueType.Numeric) {
      return (
        <span>
          <span className="font-bold">
            {(values.valueNumeric ?? 0).toLocaleString()}{" "}
            {pluralize(values.feature.name, values.valueNumeric ?? 0)}
          </span>{" "}
          {values.metricPeriod &&
            MetricPeriodDisplay[
              values.metricPeriod as CreateOrUpdateConditionRequestBodyMetricPeriodEnum
            ]}
        </span>
      );
    }

    if (values.valueType === EntitlementValueType.Unlimited) {
      return (
        <span className="font-bold">
          Unlimited {pluralize(values.feature.name)}
        </span>
      );
    }

    if (
      values.valueType === EntitlementValueType.Boolean &&
      !values.valueBool
    ) {
      return (
        <span className="font-bold">No {pluralize(values.feature.name)}</span>
      );
    }

    return <span className="font-bold">{pluralize(values.feature.name)}</span>;
  };

  const getEntitlementIncludeDescription = () => {
    if (!usageType) {
      return "";
    }
    if (usageType.value === "included") {
      return "🆓 Feature is included with plan at no additional charge.";
    }

    if (usageType.value === "pay_in_advance") {
      return "🪑 Bill for this feature based on upfront usage commitment, e.g. seats, storage";
    }

    if (usageType.value === "pay_as_you_go") {
      return "📈 Bill for this feature according to incremental usage, e.g. queries, events, actions";
    }

    return "";
  };

  return (
    <>
      {isBillableAssociationSupported() && (
        <>
          <Separator type="normal" className="mt-12" />
          <div className="px-12 pt-12 ">
            <div className="flex flex-row justify-between">
              <div className="text-base mb-6">
                Monetization for <strong>{values.feature.name}</strong>
              </div>
            </div>

            <div className="flex flex-row justify-between space-x-4">
              <FormikSelect
                label={"Type"}
                name={`usage_type`}
                selectedOption={
                  featureBillingTypes.find(
                    (option) =>
                      option.value === values.priceBehavior ||
                      option.value === usageType?.value,
                  ) ?? {
                    value: "included",
                    label: "Included in plan",
                  }
                }
                options={featureBillingTypes.filter((option) => {
                  if (values.feature.featureType === "trait") {
                    return (
                      option.value === "pay_in_advance" ||
                      option.value === "included"
                    );
                  }

                  if (values.feature.featureType === "event") {
                    return (
                      option.value === "pay_as_you_go" ||
                      option.value === "included"
                    );
                  }

                  return option.value === "included";
                })}
                maxNumberOfSelected={1}
                onChange={(option: Option) => {
                  if (!option) {
                    setIsPaidEntitlement(false);
                  }
                  setUsageType(option);
                }}
              />
              <span className="whitespace whitespace-pre-wrap line-clamp-2 pt-2 pb-2 mt-5 flex items-center leading-none">
                {getEntitlementIncludeDescription()}
              </span>
            </div>

            {usageType && isPaidEntitlement && (
              <div className="flex flex-row justify-between space-x-4 mt-6">
                <FormikAsyncSelect
                  label="Stripe monthly price"
                  name={`${name}monthlyMeteredPrice`}
                  defaultOptions
                  selectedOption={() => {
                    if (values.monthlyMeteredPrice) {
                      return {
                        label: (
                          <UsageBasedPriceOptionLabel
                            prop={values.monthlyMeteredPrice}
                          />
                        ),
                        value: values.monthlyMeteredPrice,
                      };
                    } else {
                      return null;
                    }
                  }}
                  maxNumberOfSelected={1}
                  loadOptions={listBillingPrices}
                  loadOptionsMappers={{
                    requestFilter: {
                      interval: "month",
                      usageType:
                        usageType?.value === "pay_as_you_go"
                          ? "metered"
                          : "licensed",
                    },
                    mapperFunction: (
                      price: BillingPriceDetailResponseData,
                    ) => ({
                      value: price,
                      label: <UsageBasedPriceOptionLabel prop={price} />,
                    }),
                  }}
                  onChange={async (options: Option) => {
                    if (!options) {
                      await setFieldValue(`${name}monthlyMeteredPriceId`, null);
                      return null;
                    }
                    await setFieldValue(
                      `${name}monthlyMeteredPriceId`,
                      options.value.priceId,
                    );
                    await setFieldValue(
                      `${name}priceBehavior`,
                      usageType?.value,
                    );
                    values.price = options.value;
                    values.interval = "month";
                  }}
                />

                <FormikAsyncSelect
                  label="Stripe yearly price"
                  defaultOptions
                  name={`${name}yearlyMeteredPrice`}
                  selectedOption={() => {
                    if (values.yearlyMeteredPrice) {
                      return {
                        label: (
                          <UsageBasedPriceOptionLabel
                            prop={values.yearlyMeteredPrice}
                          />
                        ),
                        value: values.yearlyMeteredPrice,
                      };
                    } else {
                      return null;
                    }
                  }}
                  maxNumberOfSelected={1}
                  loadOptions={listBillingPrices}
                  loadOptionsMappers={{
                    requestFilter: {
                      interval: "year",
                      usageType:
                        usageType?.value === "pay_as_you_go"
                          ? "metered"
                          : "licensed",
                    },
                    mapperFunction: (
                      price: BillingPriceDetailResponseData,
                    ) => ({
                      value: price,
                      label: <UsageBasedPriceOptionLabel prop={price} />,
                    }),
                  }}
                  onChange={async (options: Option) => {
                    if (!options) {
                      await setFieldValue(`${name}yearlyMeteredPriceId`, null);
                      return null;
                    }
                    await setFieldValue(
                      `${name}yearlyMeteredPriceId`,
                      options.value.priceId,
                    );
                    values.price = options.value;
                    values.interval = "year";
                  }}
                />
              </div>
            )}
          </div>
        </>
      )}

      {values.feature &&
        values.plan &&
        (isPaidEntitlement === false || !isBillableAssociationSupported()) && (
          <>
            <div className="h-px w-full bg-gray-300 my-12" />

            <FormRow className="px-12 justify-between items-center">
              <div className="flex flex-row items-center font-bold text-gray-500">
                <IconRound
                  name={values.feature.icon as IconNameTypes}
                  size="xs"
                />
                <div className="flex flex-col ml-3 space-y-1">
                  <div className="leading-none font-medium">
                    {values.feature.name}
                  </div>
                </div>
              </div>
              <FeatureTypeCell featureType={values.feature.featureType} />
            </FormRow>

            <FormColumn className="px-12 space-y-4 mt-6">
              <div>
                {values.feature.featureType === FeatureType.Boolean && (
                  <FormRow>
                    <div className="min-w-[25%] flex items-center">
                      <Switch
                        name={`${name}valueBool`}
                        label={values.valueBool ? "On" : "Off"}
                        labelPlacement="right"
                        checked={!!values.valueBool}
                        onCheckedChange={async (checked) => {
                          await setFieldValue(`${name}valueBool`, checked);
                        }}
                      />
                    </div>

                    <Alert size="xs" style="yellow">
                      {values.plan.companyCount} Companies in{" "}
                      <span className="font-bold">{values.plan.name}</span> Plan
                      get access to {valueToText(values)}
                    </Alert>
                  </FormRow>
                )}

                {values.feature.featureType === FeatureType.Trait && (
                  <FormRow>
                    <FormikControl
                      control="select"
                      label="Type"
                      name={`${name}valueType`}
                      options={[
                        {
                          label: "Numerical",
                          value: EntitlementValueType.Numeric,
                        },
                        {
                          label: "No limit",
                          value: EntitlementValueType.Unlimited,
                        },
                        {
                          label: "Trait",
                          value: EntitlementValueType.Trait,
                        },
                      ]}
                    />
                    {values.valueType === EntitlementValueType.Numeric && (
                      <FormikControl
                        control="input"
                        label="Value"
                        name={`${name}valueNumeric`}
                        type="number"
                      />
                    )}
                    {values.valueType === EntitlementValueType.Trait && (
                      <FormikAsyncSelect
                        label="Trait"
                        name={`${name}trait`}
                        defaultOptions
                        loadOptions={listEntityTraitDefinitions}
                        loadOptionsMappers={loadOptionsMappers}
                        selectedOption={
                          values.trait && {
                            value: values.trait.id,
                            label: (
                              <EntityTraitLabel entityTrait={values.trait} />
                            ),
                          }
                        }
                        onChange={async (option) => {
                          await setFieldValue(
                            `${name}valueTraitId`,
                            option?.value.id,
                          );
                        }}
                      />
                    )}
                  </FormRow>
                )}

                {values.feature.featureType === FeatureType.Event && (
                  <>
                    <div className="flex flex-row flex-grow space-x-6">
                      <div className="min-w-fit flex-1">
                        <FormikControl
                          control="select"
                          label="Type"
                          name={`${name}valueType`}
                          className="min-w-fit"
                          options={[
                            {
                              label: "Numerical",
                              value: EntitlementValueType.Numeric,
                            },
                            {
                              label: "No limit",
                              value: EntitlementValueType.Unlimited,
                            },
                            {
                              label: "Trait",
                              value: EntitlementValueType.Trait,
                            },
                          ]}
                        />
                      </div>
                      {values.valueType === EntitlementValueType.Numeric && (
                        <div className="min-w-[92px] max-w-[112px]">
                          <FormikControl
                            control="input"
                            label="Value"
                            name={`${name}valueNumeric`}
                            type="number"
                            className="max-w-[10]"
                          />
                        </div>
                      )}
                      {values.valueType === EntitlementValueType.Trait && (
                        <div className="min-w-fit flex-1 max-w-[50%]">
                          <FormikAsyncSelect
                            label="Trait"
                            name={`${name}trait`}
                            defaultOptions
                            loadOptions={listEntityTraitDefinitions}
                            loadOptionsMappers={loadOptionsMappers}
                            selectedOption={
                              values.trait && {
                                value: values.trait,
                                label: (
                                  <EntityTraitLabel
                                    entityTrait={values.trait}
                                  />
                                ),
                              }
                            }
                            onChange={async (option) => {
                              await setFieldValue(
                                `${name}valueTraitId`,
                                option?.value.id,
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>

                    {values.valueType !== EntitlementValueType.Unlimited && (
                      <div className="flex flex-col mt-6 space-y-6">
                        <FormRow>
                          <div className="text-xl">Define reset</div>
                        </FormRow>
                        <FormRow>
                          <div>When does this entitlement reset</div>
                        </FormRow>
                        <FormRow>
                          <FormikControl
                            control="select"
                            name={`${name}metricPeriod`}
                            label="Period"
                            options={metricPeriodOptions}
                          />

                          {values.metricPeriod ===
                            CreateOrUpdateConditionRequestBodyMetricPeriodEnum.CurrentMonth && (
                            <FormikControl
                              control="select"
                              name={`${name}metricPeriodMonthReset`}
                              label="Date"
                              options={metricPeriodMonthResetOptions}
                              description={
                                values.metricPeriodMonthReset ==
                                  CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum.BillingCycle &&
                                "If no subscription present for a company, entitlement will reset on first of the month."
                              }
                            />
                          )}
                        </FormRow>
                      </div>
                    )}
                  </>
                )}
              </div>

              {values.feature.featureType !== FeatureType.Boolean && (
                <Alert size="xs" style="yellow">
                  {values.plan.companyCount} Companies in{" "}
                  <span className="font-bold">{values.plan.name}</span> Plan get
                  access to {valueToText(values)}
                </Alert>
              )}
            </FormColumn>
          </>
        )}
    </>
  );
};
