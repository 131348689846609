import { FormikAsyncSelect } from "@forms/FormikAsyncSelect";
import { FormikControl } from "@forms/FormikControl";
import {
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum,
  CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum,
} from "@models/api";
import { EntitlementValueType } from "@models/entitlement";
import { EntityTraitType, EntityType } from "@models/entityTrait";
import {
  ConditionMetricPeriodDisplay,
  ConditionMetricPeriodMonthResetDisplay,
  ConditionMetricPeriodMonthResets,
  ConditionMetricPeriods,
  FeatureType,
} from "@models/feature";
import { FeatureTypeCell } from "@modules/features/components/FeatureTypeCell";
import { CreateCompanyOverrideFormValues } from "@modules/features/components/overlays/CompanyOverrideOverlay/CompanyOverrideOverlay";
import { listEntityTraitDefinitions } from "@modules/settings/queries/entityTraits";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { FormColumn, FormRow } from "@ui/FormParts";
import { EntityTraitLabel } from "@ui/RuleBlock/EntityTraitLabel";
import { Switch } from "@ui/Switch";
import { useFormikContext } from "formik";

export const OverrideLimitsBlock = () => {
  const { values, setFieldValue } =
    useFormikContext<CreateCompanyOverrideFormValues>();

  const stripeIntegrationFlag = useSchematicFlag("stripe-integration-flag", {
    fallback: true,
  });

  const metricPeriodOptions = ConditionMetricPeriods.map((period) => {
    return {
      value: period,
      label: ConditionMetricPeriodDisplay[period],
    };
  });

  const metricPeriodMonthResetOptions = ConditionMetricPeriodMonthResets.filter(
    (period) => {
      if (
        period ===
        CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum.BillingCycle
      ) {
        return stripeIntegrationFlag;
      }
      return true;
    },
  ).map((period) => {
    return {
      value: period,
      label: ConditionMetricPeriodMonthResetDisplay[period],
    };
  });

  return (
    <>
      {values.feature && values.company && (
        <>
          <div className="h-px w-full bg-gray-300 my-12" />

          <FormRow className="px-12 justify-between items-center">
            <div>Define entitlements limits</div>
            <FeatureTypeCell featureType={values.feature.featureType} />
          </FormRow>

          <FormColumn className="px-12 space-y-4 mt-6">
            {values.feature.featureType === FeatureType.Boolean && (
              <FormRow>
                <Switch
                  name="valueBool"
                  label={values.valueBool ? "On" : "Off"}
                  labelPlacement="right"
                  checked={!!values.valueBool}
                  onCheckedChange={async (checked) => {
                    await setFieldValue("valueBool", checked);
                  }}
                />
              </FormRow>
            )}

            {values.feature.featureType === FeatureType.Trait && (
              <FormRow>
                <FormikControl
                  control="select"
                  label="Type"
                  name="valueType"
                  options={[
                    {
                      label: "Numerical",
                      value: EntitlementValueType.Numeric,
                    },
                    {
                      label: "No limit",
                      value: EntitlementValueType.Unlimited,
                    },
                    {
                      label: "Trait",
                      value: EntitlementValueType.Trait,
                    },
                  ]}
                />
                {values.valueType === EntitlementValueType.Numeric && (
                  <FormikControl
                    control="input"
                    label="Value"
                    name="valueNumeric"
                    type="number"
                  />
                )}
                {values.valueType === EntitlementValueType.Trait && (
                  <FormikAsyncSelect
                    label="Trait"
                    name="trait"
                    defaultOptions
                    loadOptions={listEntityTraitDefinitions}
                    loadOptionsMappers={{
                      requestFilter: {
                        entityType: EntityType.Company,
                        traitType: EntityTraitType.Number,
                      },
                      mapperFunction: (trait) => ({
                        value: trait,
                        label: <EntityTraitLabel entityTrait={trait} />,
                      }),
                      resultsFilter: (trait) => !!trait.id,
                    }}
                    selectedOption={
                      values.trait && {
                        value: values.trait.id,
                        label: <EntityTraitLabel entityTrait={values.trait} />,
                      }
                    }
                    onChange={async (option) => {
                      await setFieldValue("valueTraitId", option?.value.id);
                    }}
                  />
                )}
              </FormRow>
            )}

            {values.feature.featureType === FeatureType.Event && (
              <>
                <FormRow>
                  <FormikControl
                    control="select"
                    label="Type"
                    name="valueType"
                    options={[
                      {
                        label: "Numerical",
                        value: EntitlementValueType.Numeric,
                      },
                      {
                        label: "No limit",
                        value: EntitlementValueType.Unlimited,
                      },
                      {
                        label: "Trait",
                        value: EntitlementValueType.Trait,
                      },
                    ]}
                  />
                  {values.valueType === EntitlementValueType.Numeric && (
                    <FormikControl
                      control="input"
                      label="Value"
                      name="valueNumeric"
                      type="number"
                    />
                  )}
                  {values.valueType === EntitlementValueType.Trait && (
                    <FormikAsyncSelect
                      label="Trait"
                      name="trait"
                      defaultOptions
                      loadOptions={listEntityTraitDefinitions}
                      loadOptionsMappers={{
                        requestFilter: {
                          entityType: EntityType.Company,
                          traitType: EntityTraitType.Number,
                        },
                        mapperFunction: (trait) => ({
                          value: trait,
                          label: <EntityTraitLabel entityTrait={trait} />,
                        }),
                        resultsFilter: (trait) => !!trait.id,
                      }}
                      selectedOption={
                        values.trait && {
                          value: values.trait,
                          label: values.trait.displayName,
                        }
                      }
                      onChange={async (option) => {
                        await setFieldValue("valueTraitId", option?.value.id);
                      }}
                    />
                  )}
                </FormRow>

                {values.valueType !== EntitlementValueType.Unlimited && (
                  <div className="flex flex-col mt-6 space-y-6">
                    <FormRow>
                      <div className="text-xl">Define reset</div>
                    </FormRow>
                    <FormRow>
                      <div>When does this entitlement reset</div>
                    </FormRow>
                    <FormRow>
                      <FormikControl
                        control="select"
                        name="metricPeriod"
                        label="Period"
                        options={metricPeriodOptions}
                      />

                      {values.metricPeriod ===
                        CreateOrUpdateConditionRequestBodyMetricPeriodEnum.CurrentMonth && (
                        <FormikControl
                          control="select"
                          name="metricPeriodMonthReset"
                          label="Date"
                          options={metricPeriodMonthResetOptions}
                          description={
                            values.metricPeriodMonthReset ==
                              CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum.BillingCycle &&
                            "If no subscription present for a company, entitlement will reset on first of the month."
                          }
                        />
                      )}
                    </FormRow>
                  </div>
                )}
              </>
            )}
          </FormColumn>
        </>
      )}
    </>
  );
};
