import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { listBillingProductPrices } from "@data/billing";
import { errorMessage } from "@data/index";
import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import { FormikControl } from "@forms/FormikControl";
import { useContextQuery } from "@hooks/useContextQuery";
import {
  BillingPriceResponseData,
  ChangeSubscriptionInternalRequestBody,
  CompanyDetailResponseData,
  FeatureUsageResponseData,
  PlanGroupPlanDetailResponseData,
  UpdatePayInAdvanceRequestBody,
  UsageBasedEntitlementResponseData,
} from "@models/api";
import { PriceBehaviourType } from "@models/entitlement";
import { Plan } from "@models/plan";
import { PlanComparison } from "@modules/companies/components/overlays/PlanComparison";
import {
  checkout,
  getCheckoutData,
  listAddOnsPricesWithAddOnName,
} from "@modules/companies/queries";
import { AddOnPriceWithAddOnName } from "@modules/companies/types";
import { PriceOptionLabel } from "@modules/plans/components/overlays/PlanEditOverlay/PriceOptionLabel";
import { PriceIntervalDisplayName } from "@modules/plans/consts";
import { listPlansWithEntitlements } from "@modules/plans/queries/planConfiguration";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { FormColumn, FormRow } from "@ui/FormParts";
import { Logo } from "@ui/Logo";
import {
  Overlay,
  OverlayFooter,
  OverlayHeader,
  OverlayModal,
} from "@ui/Overlay";
import { Pill } from "@ui/Pill";
import { PlanLabel } from "@ui/PlanLabel";
import { capitalize, formatCurrency } from "@utils/strings";
import { Form, Formik, FormikHelpers } from "formik";
import React, { ReactNode, useEffect, useState } from "react";
import * as Yup from "yup";

type CompanyWithSubscriptionManagePlanOverlayProps = {
  onClose: () => void;
  onSuccess: () => void;
  company: CompanyDetailResponseData;
};

export type PayInAdvanceEntitlement = UsageBasedEntitlementResponseData &
  UpdatePayInAdvanceRequestBody & {
    featureUsage?: FeatureUsageResponseData;
  };

export type PayAsYouGoEntitlement = UsageBasedEntitlementResponseData & {
  featureUsage?: FeatureUsageResponseData;
};

type FormValues = {
  currentPlan: Plan;
  selectedPlan: Plan;
  selectedPlanId: string;
  selectedPrice: BillingPriceResponseData;
  selectedPriceId: string;
  currentPrice: BillingPriceResponseData;
  currentAddOns: AddOnPriceWithAddOnName[];
  selectedAddOns: AddOnPriceWithAddOnName[];
  selectedAddOnIds: string[];
  payInAdvance: PayInAdvanceEntitlement[];
  payAsYouGo: PayAsYouGoEntitlement[];
};

type PlanOption = Option & {
  entity: PlanGroupPlanDetailResponseData;
};

type PriceOption = {
  name: string;
  value: string;
  label: ReactNode;
  resource: BillingPriceResponseData;
};

const AddOnPriceOptionLabel = ({
  price,
}: {
  price: AddOnPriceWithAddOnName;
}) => {
  return (
    <div key={price.id}>
      <Pill
        color="gray"
        type="tag"
        text="normal"
        className="text-black font-semibold font-display items-baseline"
      >
        {price.addOnName} · {formatCurrency(price.price, price.currency)}
        {PriceIntervalDisplayName[price.interval] || ""}
      </Pill>
    </div>
  );
};

export const CompanyWithSubscriptionManagePlanOverlay = ({
  onClose,
  onSuccess,
  company,
}: CompanyWithSubscriptionManagePlanOverlayProps) => {
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FormValues | undefined>(
    undefined,
  );

  const { data: checkoutData, isLoading: isCheckoutDataLoading } =
    useContextQuery({
      queryKey: ["checkoutData", company.id],
      queryFn: () => getCheckoutData(company.id),
      retry: false,
    });

  useEffect(() => {
    if (checkoutData && company.plan) {
      const planProduct = company.billingSubscription!.products.find(
        (product) => product.id === company.plan!.billingProductId,
      )!;

      const price = {
        id: planProduct.priceId,
        price: planProduct.price,
        interval: planProduct.interval,
        externalPriceId: planProduct.priceExternalId,
        currency: planProduct.currency,
      };

      // TODO: Simplify with returning active price in addOn from api
      const addOns = checkoutData.activeAddOns.flatMap((addOn) => {
        if (!checkoutData.subscription || !addOn.billingProduct) {
          return [];
        }

        const product = checkoutData.subscription.products.find(
          (product) => product.id === addOn.billingProduct?.productId,
        );

        if (!product) {
          return [];
        }

        const priceId = product.priceId;
        const price = addOn.billingProduct.prices.find(
          (price) => price.id === priceId,
        );

        if (price) {
          return [
            {
              ...price,
              addOnName: addOn.name,
              addOnId: addOn.id,
              billingProductId: product.id,
            },
          ];
        }

        return [];
      });

      const entitlements = checkoutData.activeUsageBasedEntitlements.map(
        (entitlement) => {
          const featureUsage = checkoutData.featureUsage?.features.find(
            (feature) => feature.feature?.id === entitlement.featureId,
          );

          return {
            featureUsage,
            ...entitlement,
          };
        },
      );

      const payInAdvance = entitlements
        .filter(
          (entitlement) =>
            entitlement.priceBehavior === PriceBehaviourType.PayInAdvance,
        )
        .map((entitlement) => ({
          ...entitlement,
          priceId: entitlement.meteredPrice!.id,
          quantity: entitlement.featureUsage?.allocation ?? 0,
        }));
      const payAsYouGo = entitlements.filter(
        (entitlement) =>
          entitlement.priceBehavior === PriceBehaviourType.PayAsYouGo,
      );

      setInitialValues({
        currentPlan: checkoutData.activePlan as Plan,
        selectedPlan: checkoutData.activePlan as Plan,
        selectedPlanId: checkoutData.activePlan!.id,
        selectedPrice: price,
        selectedPriceId: price.id,
        currentPrice: price,
        currentAddOns: addOns,
        selectedAddOns: addOns,
        selectedAddOnIds: addOns.map(({ addOnId }) => addOnId),
        payInAdvance,
        payAsYouGo,
      });
    }
  }, [
    company.addOns,
    company.plan,
    company.plan?.planPeriod,
    company.billingSubscription,
    checkoutData,
  ]);

  const onSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    setLoading(true);

    try {
      const req: ChangeSubscriptionInternalRequestBody = {
        companyId: company.id,
        addOnIds: values.selectedAddOns.map((addOn) => ({
          addOnId: addOn.addOnId,
          priceId: addOn.id,
        })),
        newPlanId: values.selectedPlan.id,
        newPriceId: values.selectedPrice.id,
        payInAdvance: values.payInAdvance.map(({ priceId, quantity }) => ({
          priceId,
          quantity,
        })),
      };

      await checkout(req);

      await queryClient.invalidateQueries();

      onClose();

      setApiError(undefined);
      helpers.setSubmitting(false);
      onSuccess();
      setLoading(false);
    } catch (error) {
      console.error(error);
      setApiError(errorMessage(error));
      helpers.setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <Overlay onClose={onClose} className="flex items-center justify-center">
      {(loading || isCheckoutDataLoading) && <SchematicOverlayLoader />}
      <OverlayModal>
        <OverlayHeader
          label="Manage plan"
          title={company?.name}
          onClose={onClose}
        >
          <Logo src={company.logoUrl} alt={company.name} />
        </OverlayHeader>

        {initialValues && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={Yup.object({
              selectedPlanId: Yup.string().required("Must provide a plan"),
              selectedPriceId: Yup.string().required(
                "Must provide a price for a plan",
              ),
              selectedAddOnIds: Yup.array().test(
                "no-different-interval",
                "Only addons with same price interval could be selected",
                (_, { parent }) => {
                  if (!parent.selectedPrice) {
                    return true;
                  }

                  return !(parent.selectedAddOns || []).some(
                    ({ interval }: AddOnPriceWithAddOnName) =>
                      interval !== parent.selectedPrice.interval,
                  );
                },
              ),
            })}
          >
            {({ values, setFieldValue, setValues, setFieldTouched, dirty }) => (
              <Form className="flex flex-col">
                <FormColumn className="px-12">
                  <FormRow>
                    <FormikAsyncSelect
                      className="flex-1"
                      label="Plan"
                      name="selectedPlanId"
                      placeholder="Type to select plan..."
                      defaultOptions
                      loadOptions={listPlansWithEntitlements}
                      loadOptionsMappers={{
                        mapperFunction: (plan) => ({
                          value: plan.id,
                          label: <PlanLabel plan={plan} font="normal" />,
                          entity: plan,
                        }),
                        requestFilter: {
                          hasProductId: true,
                        },
                      }}
                      selectedOption={
                        values.selectedPlan && {
                          value: values.selectedPlan.id,
                          label: (
                            <PlanLabel
                              plan={values.selectedPlan}
                              font="normal"
                            />
                          ),
                          entity: values.selectedPlan,
                        }
                      }
                      onChange={async (option: PlanOption) => {
                        if (option?.entity) {
                          const price =
                            option.entity.monthlyPrice ||
                            option.entity.yearlyPrice;
                          await setValues({
                            ...values,
                            // @ts-expect-error Formik fix
                            selectedPrice: price || null,
                            // @ts-expect-error Formik fix
                            selectedPriceId: price?.id || null,
                            // @ts-expect-error Formik fix
                            selectedPlan: option?.entity || null,
                          });
                        } else {
                          await setValues({
                            ...values,
                            // @ts-expect-error Formik fix
                            selectedPrice: null,
                            // @ts-expect-error Formik fix
                            selectedPriceId: null,
                            // @ts-expect-error Formik fix
                            selectedPlan: option?.entity || null,
                          });
                        }
                        await setFieldTouched("selectedPriceId", true, true);
                      }}
                    />

                    {values.selectedPlan && (
                      <FormikAsyncSelect
                        key={`price-${values.selectedPlan.id}`}
                        className="flex-1"
                        defaultOptions
                        nullable
                        label="Price"
                        loadOptions={listBillingProductPrices}
                        loadOptionsMappers={{
                          requestFilter: {
                            billingProductId:
                              values.selectedPlan.billingProduct!.productId,
                            limit: 10,
                          },
                          mapperFunction: (
                            price: BillingPriceResponseData,
                          ): PriceOption => ({
                            name: price.price.toString(),
                            value: price.id,
                            label: <PriceOptionLabel price={price} />,
                            resource: price,
                          }),
                        }}
                        name="selectedPriceId"
                        placeholder="Select price"
                        onChange={async (option: PriceOption) => {
                          if (option) {
                            await setFieldTouched(
                              "selectedAddOnIds",
                              true,
                              true,
                            );
                          }

                          await setFieldValue(
                            "selectedPrice",
                            option?.resource || null,
                          );
                        }}
                        selectedOption={
                          values.selectedPrice
                            ? {
                                name: values.selectedPrice.price,
                                value: values.selectedPrice.id,
                                label: (
                                  <PriceOptionLabel
                                    price={values.selectedPrice}
                                  />
                                ),
                                resource: values.selectedPrice,
                              }
                            : null
                        }
                      />
                    )}
                  </FormRow>

                  {values.payInAdvance.length > 0 && (
                    <div className="flex flex-col space-y-4">
                      {values.payInAdvance.map((entitlement, index) => {
                        const price = entitlement.meteredPrice;
                        const staticText = price
                          ? `${formatCurrency(
                              price.price,
                              price.currency,
                            )}/${capitalize(
                              entitlement.featureUsage?.feature?.name ?? "",
                            )}${PriceIntervalDisplayName[price.interval]}`
                          : "";

                        return (
                          <FormikControl
                            key={entitlement.featureId}
                            className="relative p-4"
                            control="input"
                            type="number"
                            name={`payInAdvance[${index}].quantity`}
                            label={
                              entitlement?.featureUsage?.feature?.name ??
                              "Unnamed feature"
                            }
                            staticText={staticText}
                          />
                        );
                      })}
                    </div>
                  )}

                  {values.payAsYouGo.length > 0 && (
                    <div className="flex flex-col space-y-4">
                      {values.payAsYouGo.map((entitlement) => {
                        const price = entitlement.meteredPrice;
                        const value = price
                          ? `${formatCurrency(
                              price.price,
                              price.currency,
                            )} per ${capitalize(
                              entitlement.featureUsage?.feature?.name ?? "",
                            )}`
                          : "";

                        return (
                          <FormikControl
                            key={entitlement.featureId}
                            className="relative p-4"
                            control="input"
                            type="text"
                            name={`payAsYouGoPlaceholderValue`}
                            disabled
                            value={value}
                            label={
                              entitlement?.featureUsage?.feature?.name ??
                              "Unnamed feature"
                            }
                          />
                        );
                      })}
                    </div>
                  )}

                  <FormRow>
                    <FormikAsyncSelect
                      key="addons"
                      className="flex-1"
                      defaultOptions
                      label="Add Ons"
                      isMulti
                      description="Add Ons must have same billing period as plan"
                      loadOptions={listAddOnsPricesWithAddOnName}
                      loadOptionsMappers={{
                        mapperFunction: (
                          price: AddOnPriceWithAddOnName,
                        ): PriceOption => ({
                          // Using billingProductId as value let us select only on price per product
                          name: price.billingProductId,
                          value: price.billingProductId,
                          label: <AddOnPriceOptionLabel price={price} />,
                          resource: price,
                        }),
                      }}
                      name="selectedAddOnIds"
                      placeholder="Select add ons"
                      onChange={async (options: PriceOption[]) => {
                        await setFieldValue(
                          "selectedAddOns",
                          options.map(({ resource }) => resource),
                        );
                      }}
                      selectedOption={values.selectedAddOns.map((price) => ({
                        name: price.billingProductId,
                        value: price.billingProductId,
                        label: <AddOnPriceOptionLabel price={price} />,
                        resource: price,
                      }))}
                    />
                  </FormRow>
                </FormColumn>

                <PlanComparison
                  companyId={company.id}
                  currentPlan={values.currentPlan}
                  currentAddOns={values.currentAddOns}
                  selectedPlan={values.selectedPlan}
                  selectedAddOns={values.selectedAddOns}
                  selectedPrice={values.selectedPrice}
                  currentPrice={values.currentPrice}
                  payInAdvance={values.payInAdvance}
                  payAsYouGo={values.payAsYouGo}
                />

                {apiError && (
                  <div className="pt-12 px-12">
                    <Alert size="xs" style="red">
                      <div className="flex items-center justify-center space-x-2">
                        <div className="text-base font-body">
                          <span className="font-semibold">Uh-oh!</span>{" "}
                          {apiError}
                        </div>
                      </div>
                    </Alert>
                  </div>
                )}

                <OverlayFooter className="px-12 pb-6">
                  <Button onClick={onClose}>Cancel</Button>
                  <Button type="submit" color="blue" disabled={!dirty}>
                    Save changes
                  </Button>
                </OverlayFooter>
              </Form>
            )}
          </Formik>
        )}
      </OverlayModal>
    </Overlay>
  );
};
