import { Pill } from "@components/ui/Pill";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { PlanGroupPlanDetailResponseData } from "@models/api";
import { FeatureType } from "@models/feature";
import { EntitlementCell } from "@modules/features";
import { PriceInfoBlock } from "@modules/plans/components/PriceInfoBlock";
import { PlanSubview } from "@modules/plans/types";
import { Button } from "@ui/Button";
import { Icon, IconRound } from "@ui/Icon";
import { IconNameTypes } from "@ui/Icon/consts";
import { PlanLabel } from "@ui/PlanLabel";
import pluralize from "pluralize";
import React from "react";
import { Link } from "react-router-dom";

type PlanConfigurationCardProps = {
  plan: PlanGroupPlanDetailResponseData;
  onClose: () => void;
};

export const PlanConfigurationCard = ({
  plan,
  onClose,
}: PlanConfigurationCardProps) => {
  const navigate = useNavigateEnvironment();

  const onAddEnvironmentButtonClick = () => {
    navigate(`plans/${plan.id}/${PlanSubview.Entitlements}`);
  };

  return (
    <div className="min-h-80 min-w-60 bg-gray-100 flex flex-col border border-blue-100 rounded-lg relative">
      <div className="flex flex-col border-b border-blue-100 p-6 min-h-[123px] max-h-[123px] h-[123px]">
        <button
          type="button"
          onClick={onClose}
          className="inline-flex absolute  top-0.5 right-1 hover:cursor-pointer text-black/50 hover:text-blue-400"
        >
          <Icon name="close" className="text-2xl" />
        </button>

        <PlanLabel plan={plan} />

        <div className="text-sm text-gray-500 leading-none mt-4 mb-1">
          {pluralize("Company", plan.companyCount, true)}
        </div>

        <div className="text-sm text-gray-600">
          {plan.billingProduct && (
            <div className="flex items-center">
              <div className="inline-flex items-center justify-center w-5 h-5 text-blue-400 border border-blue-100 rounded-full text-center mr-1 leading-none">
                <Icon name="stripe" />
              </div>

              <PriceInfoBlock
                planId={plan.id}
                items={plan.billingProduct.prices}
              />

              {!plan.monthlyPrice && !plan.yearlyPrice && (
                <Pill color="red" text="normal" type="rounded" className="ml-2">
                  No default price on product
                </Pill>
              )}
            </div>
          )}
        </div>

        {plan.isTrialable && (
          <div className="mt-1">
            <Pill className="text-sm" text="normal">
              Trial supported
            </Pill>
          </div>
        )}
      </div>

      {plan.entitlements?.length === 0 && (
        <div className="flex flex-col space-y-3 p-6">
          <div className="text-sm">No entitlement yet</div>
          <div>
            <Button
              color="white"
              className="text-sm w-auto !text-blue-400 hover:!text-white"
              onClick={onAddEnvironmentButtonClick}
            >
              Add Entitlements
            </Button>
          </div>
        </div>
      )}

      {plan.entitlements?.length > 0 && (
        <div className="flex flex-col p-6 space-y-3">
          {plan.entitlements.map((entitlement, index) => {
            const feature = entitlement.feature;
            return (
              <React.Fragment key={entitlement.id}>
                {index < 4 && (
                  <div className="flex items-center">
                    <div className="mr-2">
                      <IconRound
                        className="bg-gray-300/70"
                        name={entitlement.feature!.icon as IconNameTypes}
                        size="xs"
                      />
                    </div>
                    {feature?.featureType === FeatureType.Boolean ? (
                      <div className="text-sm">{feature.name}</div>
                    ) : (
                      <div className="text-sm">
                        <EntitlementCell
                          entitlement={entitlement}
                          showSmallText={false}
                        />
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            );
          })}

          {plan.entitlements?.length > 4 && (
            <div className="pt-2">
              <Link
                to={`../plans/${plan.id}/${PlanSubview.Entitlements}`}
                className="font-medium text-sm text-blue-400 hover:text-blue-800"
              >
                +{plan.entitlements.length - 3} More
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
