import { newPillColorLookup } from "@consts/colors";
import {
  WebhookEventStatus,
  WebhookRequestType,
  WebhookStatus,
} from "@models/webhook";

export enum IntegrationsPage {
  HubSpotIntegration = "hubspot",
  SegmentIntegration = "segment",
  StripeIntegration = "stripe",
  ClerkIntegration = "clerk",
  Webhook = "webhooks",
}

export const WebhookRequestTypeDescription: Record<WebhookRequestType, string> =
  {
    [WebhookRequestType.CompanyUpdated]: "Occurs when a company has changed",
    [WebhookRequestType.UserUpdated]: "Occurs when a user has changed",
    [WebhookRequestType.PlanUpdated]: "Occurs when a plan has changed",
    [WebhookRequestType.PlanEntitlementUpdated]:
      "Occurs when a plan entitlement has changed",
    [WebhookRequestType.CompanyOverrideUpdated]:
      "Occurs when a company override has changed",
    [WebhookRequestType.FeatureUpdated]: "Occurs when a feature has changed",
    [WebhookRequestType.FlagUpdated]: "Occurs when a flag has changed",
    [WebhookRequestType.FlagRulesUpdated]:
      "Occurs when a flag's targeting has changed",

    [WebhookRequestType.CompanyCreated]:
      "Occurs when a company has been created",
    [WebhookRequestType.UserCreated]: "Occurs when a user has been created",
    [WebhookRequestType.PlanCreated]: "Occurs when a plan has been created",
    [WebhookRequestType.PlanEntitlementCreated]:
      "Occurs when a plan entitlement has been created",
    [WebhookRequestType.CompanyOverrideCreated]:
      "Occurs when a company override has been created",
    [WebhookRequestType.FeatureCreated]:
      "Occurs when a feature has been created",
    [WebhookRequestType.FlagCreated]: "Occurs when a flag has been created",

    [WebhookRequestType.CompanyDeleted]:
      "Occurs when a company has been deleted",
    [WebhookRequestType.UserDeleted]: "Occurs when a user has been deleted",
    [WebhookRequestType.PlanDeleted]: "Occurs when a plan has been deleted",
    [WebhookRequestType.PlanEntitlementDeleted]:
      "Occurs when a plan entitlement has been deleted",
    [WebhookRequestType.CompanyOverrideDeleted]:
      "Occurs when a company override has been deleted",
    [WebhookRequestType.FeatureDeleted]:
      "Occurs when a feature has been deleted",
    [WebhookRequestType.FlagDeleted]: "Occurs when a flag has been deleted",

    [WebhookRequestType.SubscriptionTrialEnded]: "Occurs when a trial ends",
  };

export const pillColorWebhookStatus = newPillColorLookup({
  [WebhookStatus.Active]: "green",
  [WebhookStatus.Inactive]: "gray",
});

export const pillColorWebhookEventStatus = newPillColorLookup({
  [WebhookEventStatus.Success]: "green",
  [WebhookEventStatus.Pending]: "yellow",
  [WebhookEventStatus.Failed]: "red",
});

export const ClerkWebhookEvents = [
  "organization.created",
  "organization.updated",
  "organization.deleted",
  "organizationMembership.created",
  "organizationMembership.updated",
  "organizationMembership.deleted",
  "user.created",
  "user.updated",
  "user.deleted",
];
