import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import { PlanGroupPlanDetailResponseData } from "@models/api";
import { PlanType } from "@models/plan";
import { listPlans } from "@modules/plans";
import { PlansConfigurationViewFormValues } from "@modules/plans/components/PlansConfigurationView";
import { Alert } from "@ui/Alert";
import { Elevate } from "@ui/Elevate";
import { LabeledTooltip } from "@ui/LabeledTooltip";
import { PlanLabel } from "@ui/PlanLabel";
import { useFormikContext } from "formik";

type DefaultPlanOption = Option & {
  entity: PlanGroupPlanDetailResponseData;
};

export const DefaultPlanElevate = () => {
  const { setFieldValue, values, touched } =
    useFormikContext<PlansConfigurationViewFormValues>();

  return (
    <Elevate>
      <div className="w-full flex justify-between items-center">
        <div className="text-xl">Default plan</div>
        <div className="flex items-center space-x-8">
          <div className="text-sm flex justify-center items-center whitespace-nowrap">
            Companies without another base plan will be assigned to this plan.
            <LabeledTooltip
              description="Plans that are linked to billing products cannot be selected as the default plan"
              position="relative"
              placement="top-left"
              size="lg"
            />
          </div>
          <FormikAsyncSelect
            className="max-w-xs"
            name="defaultPlanId"
            placeholder="No default plan selected"
            defaultOptions
            loadOptions={listPlans(PlanType.Plan)}
            loadOptionsMappers={{
              mapperFunction: (plan) => ({
                entity: plan,
                label: <PlanLabel plan={plan} font="normal" />,
                value: plan.id,
              }),
              requestFilter: {
                withoutProductId: true,
              },
            }}
            selectedOption={
              values?.defaultPlan && {
                value: values.defaultPlan.id,
                label: <PlanLabel font="normal" plan={values.defaultPlan} />,
                entity: values.defaultPlan,
              }
            }
            onChange={async (option: DefaultPlanOption) => {
              await setFieldValue("defaultPlan", option?.entity);
              await setFieldValue("defaultPlanId", option?.value);
            }}
          />
        </div>
      </div>
      {touched.defaultPlanId && (
        <div className="w-full mt-4">
          <Alert size="xs" style="yellow" className="w-full">
            If you change the default plan, that plan's current audience will be
            overwritten.
          </Alert>
        </div>
      )}
    </Elevate>
  );
};
