import { TableLoader } from "@components/loaders/TableLoader";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import useTablePagination from "@hooks/useTablePagination";
import { CompanyDetailResponseData } from "@models/api";
import { ListPlansParams } from "@models/api";
import { Plan, PlanType } from "@models/plan";
import { countPlans, listPlans } from "@modules/plans";
import { ColumnDef } from "@tanstack/react-table";
import { Diamond, DiamondStyleTypes } from "@ui/Diamond";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import { formatDate } from "@utils/date";

import { hasTrial } from "@utils/trials";
import pluralize from "pluralize";
import React, { useEffect, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { TrialPill } from "../TrialPill";

interface CompanyPlansTableProps {
  companyId: string;
  planType: PlanType;
}

export const CompanyPlansTable = ({
  companyId,
  planType,
}: CompanyPlansTableProps) => {
  const navigate = useNavigateEnvironment();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ListPlansParams>({
    companyId,
    planType,
  });
  const entityTitle = planType === PlanType.Plan ? "Plan" : "Add On";

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setFilter(searchTerm === "" ? { companyId } : { companyId, q: searchTerm });
  }, [companyId, searchTerm]);

  const { company } = useOutletContext<{
    company: CompanyDetailResponseData;
  }>();

  const billingSubscription = company.billingSubscription;

  const showTrialPill = hasTrial(billingSubscription);

  const columns = useMemo<ColumnDef<Plan>[]>(() => {
    return [
      {
        id: "plan",
        header: "Name",
        accessorKey: "plan",
        cell: (cellInfo) => {
          const plan = cellInfo.row.original;
          return (
            <div className="flex flex-row items-center">
              <Diamond
                size="sm"
                className="mr-2"
                style={plan.icon as DiamondStyleTypes}
              />
              <div className="flex flex-col ml-3 space-y-1">
                <div className="leading-none font-medium truncate-md">
                  {plan.name}
                </div>
                <div className="leading-none text-gray-400">{plan.id}</div>
              </div>
              {showTrialPill && (
                <TrialPill
                  trialEnd={billingSubscription?.trialEnd || 0}
                  companyId={company.id}
                />
              )}
            </div>
          );
        },
      },
      {
        id: "lastEdited",
        header: "Last edited",
        accessorKey: "lastEdited",
        cell: (cellInfo) => {
          const plan = cellInfo.row.original;
          return (
            <div className="leading-none text-gray-400">
              {formatDate(plan.updatedAt)}
            </div>
          );
        },
      },
    ];
  }, []);

  const getHeaderText = (count: number) => {
    return pluralize(entityTitle, count, true);
  };

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<Plan, ListPlansParams>(
    ["plans", planType],
    listPlans(planType),
    countPlans(planType),
    filter,
    getHeaderText,
  );

  const onRowClick = (row: Plan) => {
    navigate(`plans/${row.id}`);
  };

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const noPlans = countQuery?.data?.count === 0;
  const loading =
    listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  if (loading) return <TableLoader />;

  return (
    <div className="mb-8">
      {!noPlans && (
        <>
          <TableHeader
            headerText={headerText}
            searchPlaceholder={`Find ${entityTitle}`}
            onSearch={handleSearch}
          />
          <div>
            <Table
              className="mt-8"
              columns={columns}
              data={listQuery.data}
              onRowClick={onRowClick}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
            />
          </div>
        </>
      )}
    </div>
  );
};
