import { EventsBlankState } from "@components/blanks/pages/EventsBlankState";
import { TableHeader } from "@components/ui/TableHeader";
import { listEvents } from "@data/events";
import { EventDetailResponseData } from "@models/api";
import { EventStatus } from "@models/events";
import { ColumnDef } from "@tanstack/react-table";
import { CompanyCell } from "@ui/CompanyCell";
import { InfiniteTable } from "@ui/InfiniteTable";
import { Pill } from "@ui/Pill";
import { PillGroup } from "@ui/PillGroup";
import { UserInitials } from "@ui/UserInitials";
import { formatDate } from "@utils/date";

import { useMemo, useState } from "react";
import { EventOverlay } from "./EventOverlay";
import { pillColorEventStatus } from "../helpers";

export const EventsTable = () => {
  const [eventOverlay, setEventOverlay] = useState<string | null>(null);

  const columns = useMemo<ColumnDef<EventDetailResponseData>[]>(
    () => [
      {
        id: "timestamp",
        accessorKey: "timestamp",
        cell: (cellInfo) => {
          return (
            <div className="inline-block space-y-1">
              <div className="leading-none text-gray-400">
                {formatDate(cellInfo.row.original.capturedAt)}
              </div>
            </div>
          );
        },
      },
      {
        id: "event",
        header: "Event",
        accessorKey: "event",
        size: 180,
        cell: (cellInfo) => {
          const orig = cellInfo.row.original;
          const event = orig.subtype || orig.type;
          return (
            <Pill
              text="code"
              title={event}
              className="overflow-x-auto inline-flex max-w-full truncate overflow-hidden"
            >
              <span className="max-w-full truncate">{event}</span>
            </Pill>
          );
        },
      },
      {
        id: "company",
        header: "Company",
        accessorKey: "company",
        size: 220,
        cell: (cellInfo) => {
          const company = cellInfo.row.original.company;
          return <CompanyCell company={company} />;
        },
      },
      {
        id: "user",
        header: "User",
        accessorKey: "user",
        size: 220,
        cell: (cellInfo) => {
          const companyUser = cellInfo.row.original.user;
          return (
            <div className="flex truncate" title={companyUser?.name}>
              {companyUser && (
                <UserInitials
                  name={companyUser.name}
                  showName={true}
                  size="xs"
                />
              )}
            </div>
          );
        },
      },
      {
        id: "status",
        header: "Status",
        accessorKey: "status",
        cell: (cellInfo) => {
          const status = cellInfo.row.original.status;
          return (
            <Pill color={pillColorEventStatus(status as EventStatus)}>
              {status}
            </Pill>
          );
        },
      },
      {
        id: "features",
        header: "Features",
        accessorKey: "features",
        size: 330,
        maxSize: 330,
        cell: (cellInfo) => {
          const features = cellInfo.row.original.features;
          if (!features) return <></>;

          return <PillGroup items={features} direction="column" />;
        },
      },
      {
        id: "properties",
        header: "Properties",
        accessorKey: "properties",
        size: 200,
        cell: (cellInfo) => {
          return (
            <div className="inline-block max-w-full overflow-hidden">
              <div className="leading-none text-gray-400 overflow-hidden max-w-[300px] text-ellipsis">
                {cellInfo.row.original.bodyPreview}
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <TableHeader headerText="Event Stream" />

      <InfiniteTable
        queryKey={["events"]}
        queryFn={listEvents}
        columns={columns}
        onRowClick={(row: EventDetailResponseData) => setEventOverlay(row.id)}
        blankStateComponent={<EventsBlankState />}
      />

      {eventOverlay && (
        <EventOverlay id={eventOverlay} onClose={() => setEventOverlay(null)} />
      )}
    </>
  );
};
