import { useUser } from "@clerk/clerk-react";
import { ClerkUserPermission } from "@models/clerkUser";
import { OrganizationMembership } from "@modules/settings/components/tables/TeamTable";
import { useParams } from "react-router-dom";

export const usePermission = (requiredPermission: ClerkUserPermission) => {
  const { environmentId } = useParams() as { environmentId: string };
  const { isLoaded, user } = useUser();

  if (!isLoaded) return false;

  if (requiredPermission === ClerkUserPermission.none) return true;

  const membership = user?.organizationMemberships[0] as OrganizationMembership;

  if (membership?.role === "org:admin") return true;

  const permissions =
    membership?.publicMetadata.permissions?.[environmentId] || [];

  return permissions.includes(requiredPermission);
};
