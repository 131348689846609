import { FormikControl } from "@forms/FormikControl";
import { FormikSelect } from "@forms/FormikSelect";
import { PlansConfigurationViewFormValues } from "@modules/plans/components/PlansConfigurationView";
import { Elevate } from "@ui/Elevate";
import { useFormikContext } from "formik";
import { PaymentMethodRequiredAlert } from "../PaymentMethodRequiredAlert";

const selectOptions = [
  {
    label: "Require payment method to start trial",
    value: true,
  },
  {
    label: "Don't require payment method to start trial",
    value: false,
  },
];

export const TrialsConfigurationElevate = () => {
  const { values } = useFormikContext<PlansConfigurationViewFormValues>();

  return (
    <Elevate className="flex flex-col">
      <div className="flex flex-row space-x-16">
        <div className="flex flex-col space-y-4 max-w-[220px]">
          <div className="text-xl">Trials</div>
          <div className="flex flex-col space-y-4 text-gray-600 text-sm">
            <div>
              Enable trials for any paid plan associated with a Stripe product
              by editing the plan.
            </div>
            <div>
              Only companies subscribing to a plan for the first time can access
              the trial.
            </div>
            <div>Trial type changes only impacts new trial subscribers.</div>
          </div>
        </div>
        <div className="flex flex-col space-y-8 w-full">
          <div className="flex-1">
            <FormikSelect
              label="Payment Method"
              name="trialPaymentMethodRequired"
              options={selectOptions}
              selectedOption={selectOptions.find(
                (c) => c.value === values.trialPaymentMethodRequired,
              )}
              selectClassName="z-[1]"
            />

            <PaymentMethodRequiredAlert
              defaultPlan={values.defaultPlan}
              trialPaymentMethodRequired={values.trialPaymentMethodRequired}
            />
          </div>
          <div className="flex-1">
            <FormikControl
              control="input"
              name="trialDays"
              type="number"
              label="Default Trial Period in Days"
              placeholder="Trial Period in Days"
            />
          </div>
        </div>
      </div>
    </Elevate>
  );
};
