export const coerceDate = (date: Date | string): Date => {
  if (typeof date === "string") {
    return new Date(date);
  }

  return date;
};

export const formatDate = (
  maybeDate?: Date | string,
  time: boolean = true,
): string => {
  if (!maybeDate) return "";

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "2-digit",
  };

  const options: Intl.DateTimeFormatOptions = time
    ? {
        ...dateOptions,
        ...timeOptions,
      }
    : dateOptions;

  const date = coerceDate(maybeDate);
  if (date.toString() === "Invalid Date" && typeof maybeDate === "string") {
    // If we can't format it as a date, just return the input string
    return maybeDate;
  } else if (date.toString() === "Invalid Date") {
    // This case should never happen, as if maybeDate is not a string, it is a date already, and
    // thus coerceDate should have had no effect
    return "";
  }

  return coerceDate(date).toLocaleDateString("en-US", options);
};

export const formatReadableDate = (date: Date): string => {
  if (!date) return "";

  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  return date.toLocaleDateString("en-US", options);
};

export const isDateBeforeCurrent = (date: Date): boolean => {
  return date.getTime() < new Date().getTime();
};

export const timeAgo = (date: string | Date) => {
  const formatter = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
  const diff = new Date().getTime() - new Date(date).getTime();

  const diffSeconds = Math.floor(diff / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffWeeks = Math.floor(diffDays / 7);
  const diffMonths = Math.floor(diffDays / 30.44);
  const diffYears = Math.floor(diffDays / 365.25);

  if (diffYears > 0) return formatter.format(-diffYears, "year");
  if (diffMonths > 0) return formatter.format(-diffMonths, "month");
  if (diffWeeks > 0) return formatter.format(-diffWeeks, "week");
  if (diffDays > 0) return formatter.format(-diffDays, "day");
  if (diffHours > 0) return formatter.format(-diffHours, "hour");
  if (diffMinutes > 0) return formatter.format(-diffMinutes, "minute");
  return formatter.format(-diffSeconds, "second");
};

export const toUTCDate = (date: string | Date) => {
  const d = new Date(date);
  return new Date(
    Date.UTC(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
      d.getSeconds(),
      d.getMilliseconds(),
    ),
  );
};

export const formatUTCDate = (
  maybeDate?: Date | string,
  includeTime: boolean = true,
): string => {
  if (!maybeDate) return "";

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "UTC",
  };

  const dateOptions: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
  };

  const options: Intl.DateTimeFormatOptions = includeTime
    ? {
        ...dateOptions,
        ...timeOptions,
      }
    : dateOptions;

  const date = coerceDate(maybeDate);
  if (date.toString() === "Invalid Date" && typeof maybeDate === "string") {
    return maybeDate;
  } else if (date.toString() === "Invalid Date") {
    return "";
  }

  const formatted = date.toLocaleString("en-US", options);
  return `${formatted} UTC`;
};
