import { BillingSubscriptionView } from "@models/api";

export const hasTrial = (subscription: BillingSubscriptionView | undefined) => {
  return subscription && subscription.status === "trialing";
};

export const getTrialEndDate = (trialEnd: number | undefined) => {
  if (!trialEnd) {
    return new Date(0);
  }
  return new Date(trialEnd * 1000 || 0);
};
