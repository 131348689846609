import { Alert } from "@components/ui/Alert";
import { PlanGroupPlanDetailResponseData } from "@models/api";
import { PlanLabel } from "@ui/PlanLabel";
import cx from "classnames";

type PaymentMethodRequiredAlertProps = {
  trialPaymentMethodRequired: boolean;
  defaultPlan?: PlanGroupPlanDetailResponseData;
  className?: string;
};

export const PaymentMethodRequiredAlert = ({
  defaultPlan,
  trialPaymentMethodRequired,
  className,
}: PaymentMethodRequiredAlertProps) => {
  return (
    <Alert
      style="gray"
      size="xs"
      className={cx("w-full space-y-6 pt-12 -mt-8", className)}
    >
      <div className="flex flex-col space-y-2">
        {!trialPaymentMethodRequired && (
          <>
            {defaultPlan && (
              <div className="flex">
                <span className="mr-2">
                  At end of trial, company will be downgraded to the default
                  plan
                </span>
                <span className="inline-flex before:mr-1 after:ml-1 before:content-['('] after:content-[')']">
                  <PlanLabel
                    plan={defaultPlan}
                    font="normal"
                    className="text-base -ml-2"
                  />
                </span>
              </div>
            )}
            {!defaultPlan && (
              <div>At end of trial, company will be removed from the plan.</div>
            )}
          </>
        )}
        {trialPaymentMethodRequired && (
          <div>
            At end of trial, the subscription will start and the company will be
            billed.
          </div>
        )}
      </div>
    </Alert>
  );
};
