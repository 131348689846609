import { DropdownDots } from "@components/ui/DropdownDots";
import { Icon } from "@components/ui/Icon";
import { TableHeader } from "@components/ui/TableHeader";
import { useContextQuery } from "@hooks/useContextQuery";
import { BillingSubscriptionView } from "@models/api";
import { updateCompanySubscriptionTrialEnd } from "@modules/companies/queries";
import { getPlansConfiguration } from "@modules/plans/queries/planConfiguration";
import { formatReadableDate } from "@utils/date";
import { getTrialEndDate } from "@utils/trials";
import { useState } from "react";
import { TrialEditOverlay } from "../overlays/TrialEditOverlay";

type TrialCellProps = {
  subscription: BillingSubscriptionView;
};

export const TrialCell = ({ subscription }: TrialCellProps) => {
  const [ediTrialOverlay, setEditTrialOverlay] = useState(false);
  const trialEnd = getTrialEndDate(subscription?.trialEnd);
  const onDelete = () => {
    updateCompanySubscriptionTrialEnd(subscription.id, { trialEnd: undefined });
  };

  const onEdit = () => {
    setEditTrialOverlay(true);
  };

  const closeTrialEditOverlay = () => {
    setEditTrialOverlay(false);
  };

  // Fetch active plans for the current environment
  const { data: planConfiguration, isLoading } = useContextQuery({
    queryKey: ["getActivePlans"],
    queryFn: async () => {
      const config = await getPlansConfiguration();
      return config;
    },
  });

  const paymentMethodRequired =
    subscription.trialEndSetting == "subscribe" || false;

  return (
    <>
      {!isLoading && planConfiguration && (
        <>
          <TableHeader headerText={"Trial"} className="!pb-0" />
          <div className="bg-white shadow-lg border border-gray-400/15 rounded-lg p-4 px-6">
            <div className=" flex flex-row items-center space-x-8">
              <div className="flex flex-row items-center text-2xl flex-1">
                <Icon
                  name="watch-pocket"
                  className="-ml-3 text-6xl text-gray-400/70 !leading-none"
                />{" "}
                Ends in {formatReadableDate(trialEnd)}
              </div>

              <div className="flex">
                <span className="font-medium">End of trial</span>:{" "}
                {paymentMethodRequired ? (
                  <>Subscription starts and company is billed</>
                ) : (
                  <>Downgrade to default plan</>
                )}
              </div>

              <DropdownDots
                size="md"
                links={[
                  {
                    label: "Edit trial",
                    onClick: onEdit,
                  },
                  {
                    label: "Delete trial",
                    onClick: onDelete,
                  },
                ]}
              />
            </div>
          </div>
          {ediTrialOverlay && (
            <TrialEditOverlay
              paymentMethodRequired={paymentMethodRequired}
              trialEnd={trialEnd}
              defaultPlan={planConfiguration.defaultPlan}
              onClose={closeTrialEditOverlay}
              subscriptionId={subscription.id}
            />
          )}
        </>
      )}
    </>
  );
};
