import * as integrationsApi from "@data/integrations";
import { useContextQuery } from "@hooks/useContextQuery";

export const useStripeIntegration = () => {
  const getIntegrations = useContextQuery({
    queryKey: ["getIntegrations"],
    queryFn: () => integrationsApi.listIntegrations({ type: "stripe" }),
  });

  const hasStripeIntegration =
    getIntegrations.data && getIntegrations.data.length > 0;

  return {
    hasStripeIntegration,
    isLoading: getIntegrations.isLoading,
    error: getIntegrations.error,
    integrations: getIntegrations.data,
  };
};
