import { Icon } from "@components/ui/Icon";
import { useCurrentEnvironment } from "@hooks/useCurrentEnvironment";
import { Pill } from "@ui/Pill";
import { useNavigate } from "react-router-dom";
import { CompanySubview } from "../types";

type TrialPillProps = {
  trialEnd?: number;
  companyId?: string;
};

export const TrialPill = ({ trialEnd, companyId }: TrialPillProps) => {
  const { environment } = useCurrentEnvironment();
  const navigate = useNavigate();

  if (!trialEnd) {
    return null;
  }

  const trialEndDate = new Date(trialEnd * 1000);

  return (
    <>
      {companyId && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            navigate(
              `/${environment?.id}/companies/${companyId}/${CompanySubview.Subscription}`,
            );
          }}
        >
          <Pill type="rounded" text="normal">
            Trial Ends {trialEndDate.toLocaleDateString()}{" "}
            <Icon name="pencil" />
          </Pill>
        </span>
      )}
      {!companyId && (
        <Pill type="rounded" text="normal">
          Trial Ends {trialEndDate.toLocaleDateString()}
        </Pill>
      )}
    </>
  );
};
