import { deleteReq, get, post, put } from "@data/index";
import {
  BillingSubscriptionResponseData,
  ChangeSubscriptionInternalRequestBody,
  CompanyDetailResponseData,
  ListCompaniesParams,
  ListPlansParams,
  LookupCompanyParams,
  LookupCompanyRequest,
  PreviewSubscriptionChangeResponseData,
  UpsertCompanyRequestBody,
  UpdateCustomerSubscriptionTrialEndResponse,
  UpdateTrialEndRequestBody,
  UpdateCompanyPlansRequestBody,
  CheckoutDataResponseData,
} from "@models/api";
import { Count } from "@models/count";
import { Delete } from "@models/delete";
import { Plan, PlanType } from "@models/plan";
import { AddOnPriceWithAddOnName } from "@modules/companies/types";

export const countCompanies = async (
  flt?: ListCompaniesParams,
): Promise<Count> => {
  return get<Count, ListCompaniesParams>("companies/count", flt);
};

export const createCompany = async (
  req: UpsertCompanyRequestBody,
): Promise<CompanyDetailResponseData> => {
  return post<CompanyDetailResponseData, UpsertCompanyRequestBody>(
    "companies",
    req,
  );
};

export const deleteCompany = async (id: string): Promise<Delete> => {
  return deleteReq(`companies/${id}`);
};

export const getCompany = async (
  companyId: string,
): Promise<CompanyDetailResponseData> => {
  return get<CompanyDetailResponseData>(`companies/${companyId}`);
};

export const lookupCompany = async (
  params: LookupCompanyRequest,
): Promise<CompanyDetailResponseData> => {
  return get<CompanyDetailResponseData, LookupCompanyParams>(
    `companies/lookup`,
    // @ts-expect-error Transforming into json as query is not parsed correctly for some reason
    { keys: JSON.stringify(params.keys) },
  );
};

export const listCompanies = async (
  flt?: ListCompaniesParams,
): Promise<CompanyDetailResponseData[]> => {
  return get<CompanyDetailResponseData[], ListCompaniesParams>(
    "companies",
    flt,
  );
};

export const updateCompany = async (
  id: string,
  req: UpsertCompanyRequestBody,
): Promise<CompanyDetailResponseData> => {
  return put<CompanyDetailResponseData, UpsertCompanyRequestBody>(
    `companies/${id}`,
    req,
  );
};

export const listAddOnsPricesWithAddOnName = async (
  flt?: ListPlansParams,
): Promise<AddOnPriceWithAddOnName[]> => {
  const params: ListPlansParams = {
    planType: PlanType.AddOn,
    hasProductId: true,
    ...flt,
  };

  const addOns = await get<Plan[], ListPlansParams>("plans", params);

  return addOns.flatMap((addOn) =>
    addOn.billingProduct!.prices.map((price) => ({
      ...price,
      addOnName: addOn.name,
      addOnId: addOn.id,
      billingProductId: addOn.billingProduct!.productId,
    })),
  );
};

export const getCheckoutData = async (
  companyId: string,
): Promise<CheckoutDataResponseData> => {
  return get<CheckoutDataResponseData>(`checkout/${companyId}/checkout-data`);
};

export const checkout = async (
  req: ChangeSubscriptionInternalRequestBody,
): Promise<BillingSubscriptionResponseData> => {
  return post<
    BillingSubscriptionResponseData,
    ChangeSubscriptionInternalRequestBody
  >(`checkout`, req);
};

export const previewCheckout = async (
  req: ChangeSubscriptionInternalRequestBody,
): Promise<PreviewSubscriptionChangeResponseData> => {
  return post<
    PreviewSubscriptionChangeResponseData,
    ChangeSubscriptionInternalRequestBody
  >(`checkout/preview`, req);
};

export const checkoutWithoutSubscription = async (
  companyId: string,
  req: UpdateCompanyPlansRequestBody,
): Promise<CompanyDetailResponseData> => {
  return put<CompanyDetailResponseData, UpdateCompanyPlansRequestBody>(
    `checkout/company-plans/${companyId}`,
    req,
  );
};

export const updateCompanySubscriptionTrialEnd = async (
  subscriptionId: string,
  req: UpdateTrialEndRequestBody,
): Promise<UpdateCustomerSubscriptionTrialEndResponse> => {
  return put<
    UpdateCustomerSubscriptionTrialEndResponse,
    UpdateTrialEndRequestBody
  >(`billing/subscription/${subscriptionId}/edit-trial-end`, req);
};
